import React, { useState, useEffect } from 'react'
import { LeftOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Button, message, Steps, theme, Alert, Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import CreateType from './CreateType';
import { useType } from '../../context/TypeContext';
import { SERVER_URL } from '../../api';
import axios from 'axios';
import { tokenData } from '../../api/auth';
import CreateForm from './CreateForm';
import { useSurvey } from '../../context/SurveyContext';
import Appbar from '../../components/Appbar';
import CreateCategory from './CreateCategory';
import TypeList from './TypeList';
import FormList from './FormList';
import QuestionList from './QuestionList';
import CreateQuestion from './CreateQuestion';
import { useQuestions } from '../../context/QuestionContext';
import { successSwal } from '../../constants/sweetalert';

export default function AddSurvey() {

    const navigate = useNavigate()
    const [messageApi, contextHolder] = message?.useMessage();
    const { fetchTypeData } = useType()
    const { fetchSurveyData } = useSurvey()
    const { questions } = useQuestions()
    const [createType, setCreateType] = useState(false)
    const [createCategory, setCreateCategory] = useState(false)
    const [createForm, setCreateForm] = useState(false)
    const [createQuestion, setCreateQuestion] = useState(false)
    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const [surveyLates, setSurveyLates] = useState([])
    const [surveyId, setSurveyId] = useState()
    const [answers, setAnswers] = useState([])
    const [loading, setLoading] = useState(false)
    const [surveyData, setSurveyData] = useState([])
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemsDepartment, setSelectedItemsDepartment] = useState([]);

    const handleRadioChange = (e) => {
        setSelectedTypeId(e.target.value);
    };

    const handleDelete = async () => {
        if (!selectedTypeId) {
            message.error('Please select a type to delete.');
            return;
        }
        try {
            // Perform the delete operation using Axios
            await axios.delete(`${SERVER_URL}survey-types/delete/${selectedTypeId}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                },
            });
            // Optionally, you can refetch the data after deletion
            fetchTypeData()
            // Clear the selected type ID
            setSelectedTypeId(null);
            message.success('Type deleted successfully.');
        } catch (error) {
            console.error('Error deleting type:', error);
            message.error('An error occurred while deleting the type.');
        }
    };

    useEffect(() => {
        fetchSurveyId()
    }, [])

    const questionData = questions?.filter((id) => id?.survey_id === surveyId)

    const fetchSurveyId = async () => {
        try {
            const response = await axios.get(`${SERVER_URL + "survey/get-survey/" + surveyLates?.[0]?.valuses?.[0]}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setSurveyData(response?.data?.results);
            setLoading(false)
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const steps = [
        {
            title: 'ເລືອກປະເພດຟອມ',
            content:
                <TypeList
                    handleDelete={handleDelete}
                    handleRadioChange={handleRadioChange}
                    selectedTypeId={selectedTypeId}
                    setCreateType={setCreateType}
                />
        },
        {
            title: 'ສ້າງເເບບຟອມ',
            content:
                <FormList
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    selectedItemsDepartment={selectedItemsDepartment}
                    setSelectedItemsDepartment={setSelectedItemsDepartment}
                    fetchSurveyId={fetchSurveyId}
                    surveyData={surveyData}
                    setCreateForm={setCreateForm}
                    surveyLates={surveyLates} />
        },
        {
            title: 'ສ້າງຄຳຖາມ',
            content:
                <QuestionList
                    selectedTypeId={selectedTypeId}
                    surveyLates={surveyLates}
                    messageApi={messageApi}
                    contextHolder={contextHolder}
                    surveyId={surveyId}
                    setSurveyId={setSurveyId}
                    answers={answers}
                    setAnswers={setAnswers}
                    setCreateCategory={setCreateCategory}
                    setCreateQuestion={setCreateQuestion} />
        },
    ];

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps?.map((item) => ({
        key: item?.title,
        title: item?.title,
    }));

    const contentStyle = {
        // padding: 20,
        color: token.colorTextTertiary,
        // backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        // border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
    };

    const onSuccessSurvey = async () => {
        await successSwal('ສ້າງເເບບຟອມສຳເລັດ')
        fetchSurveyData()
        navigate('/survey-list')
    }

    return (
        <Appbar title={'ສ້າງຟອມ'} link={'/survey-list'} icons={<LeftOutlined />}>
            <div className=' max-sm:mt-3 max-sm:pl-[20px] max-sm:pr-[20px]'>

                <Alert
                    message={<Breadcrumb
                        items={[
                            {
                                title: (
                                    <>
                                        <LeftOutlined />
                                        <span onClick={() => {
                                            navigate('/survey-list')
                                            // fetchSurveyData()
                                        }} className=' ms-2 cursor-pointer'>ກັບຄືນລາຍການຟອມ</span>
                                    </>
                                ),
                            },
                            {
                                title: 'ສ້າງເເບບຟອມ',
                            },
                        ]}
                    />}
                    type="info"
                    closable={false}
                    className=' max-sm:hidden mb-3 shadow-sm hover:shadow-lg  shadow-slate-900 '
                />

                <Steps current={current} items={items} />

                <div style={contentStyle}>{steps[current]?.content}</div>

                <div
                    style={{
                        marginTop: 24,
                    }}
                >
                    {current < steps?.length - 0 && current !== 2 && current !== 1 && (
                        <Button
                            disabled={!selectedTypeId}
                            className=' bg-blue-600 h-[40px]'
                            type="primary"
                            onClick={() => next()}>
                            ຕໍ່ໄປ
                        </Button>
                    )}

                    {current !== 2 && current !== 0 && (
                        <Button
                            disabled={surveyData?.length === 0}
                            className=' bg-blue-600 h-[40px]'
                            type="primary"
                            onClick={() => next()}>
                            ຕໍ່ໄປ
                        </Button>
                    )}

                    {current === steps?.length - 1 && current !== 3 && (
                        <Button
                            disabled={!questionData?.length > 0 ? true : false}
                            className={` bg-blue-600 h-[40px]`}
                            type="primary"
                            onClick={onSuccessSurvey}>
                            ສຳເລັດ
                        </Button>
                    )}

                    {current > 0 && current !== 1 && (
                        <Button
                            className='h-[40px]'
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            ກັບຄືນ
                        </Button>
                    )}

                </div>

                <CreateType
                    isModalOpen={createType}
                    handleCancel={() => setCreateType(false)}
                />

                <CreateCategory
                    isModalOpen={createCategory}
                    handleCancel={() => setCreateCategory(false)}
                    surveyLates={surveyLates}
                />

                <CreateForm
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    selectedItemsDepartment={selectedItemsDepartment}
                    setSelectedItemsDepartment={setSelectedItemsDepartment}
                    fetchSurveyId={fetchSurveyId}
                    selectedTypeId={selectedTypeId}
                    setSurveyLates={setSurveyLates}
                    isModalOpen={createForm}
                    handleCancel={() => setCreateForm(false)}
                />

                <CreateQuestion
                    selectedTypeId={selectedTypeId}
                    isModalOpen={createQuestion}
                    handleCancel={() => setCreateQuestion(false)}
                    setCreateCategory={setCreateCategory}
                    surveyLates={surveyLates}
                    surveyId={surveyId}
                    setSurveyId={setSurveyId}
                    answers={answers}
                    setAnswers={setAnswers}
                    messageApi={messageApi}
                />

            </div>
        </Appbar>
    )
}

import React, { createContext, useContext, useEffect, useState } from 'react';
import { tokenData } from '../constants';
import { SERVER_URL } from '../api';
import axios from 'axios';

const QuestionContext = createContext()

export default function QuestionProvider({ children }) {

    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchquestionsData()
    }, [])

    const fetchquestionsData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/question/get-questions"}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setQuestions(response?.data?.results);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };


    // console.log("========>questions", questions)

    return (
        <QuestionContext.Provider value={{ questions, setQuestions, fetchquestionsData, loading }} >
            {children}
        </QuestionContext.Provider>
    )
}

export function useQuestions() {
    return useContext(QuestionContext)
}
import React from 'react'
import { Card, Result, Space, Tag, Button } from 'antd';
import Appbar from '../../components/Appbar';
import { useQuestions } from '../../context/QuestionContext';
import { useChoice } from '../../context/ChoiceContext';

export default function Dashboard() {

    const { questions } = useQuestions()
    const { choices } = useChoice()

    // Function to filter choices for a specific question
    const getChoicesForQuestion = (questionId, surveyId) => {
        return choices.filter(choice => choice?.question_id === questionId && choice?.survey_id === surveyId);
    };

    const columns = [
        {
            title: 'ຊື່',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'ນາມສະກຸນ',
            dataIndex: 'lastname',
            key: 'lastname',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'ຄະເເນນ',
            dataIndex: 'scoreAll',
            key: 'scoreAll',
        },
        {
            title: 'ຈັດການ',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a>ລາຍລະອຽດ</a>
                </Space>
            ),
        },
    ];

    const UserDataScore = [
        {
            id: 1,
            name: "Souksavanh",
            lastname: "Soumphonphukdee",
            answserTrue: 20,
            answserfalse: 10,
            questionAll: 30,
            scoreAll: <Tag color="#108ee9">20</Tag>
        },
        {
            id: 1,
            name: "Souksavanh",
            lastname: "Soumphonphukdee",
            answserTrue: 20,
            answserfalse: 10,
            questionAll: 30,
            scoreAll: <Tag color="#108ee9">20</Tag>
        }, {
            id: 1,
            name: "Souksavanh",
            lastname: "Soumphonphukdee",
            answserTrue: 20,
            answserfalse: 10,
            questionAll: 30,
            scoreAll: <Tag color="#108ee9">20</Tag>
        }
    ]

    return (
        <Appbar title={'Dashboard'}>
            <Result
                status="404"
                title="ຂໍອະໄພ ກຳລັງພັດທະນາ..."
                subTitle="Sorry, doing development."
            // extra={<Button type="primary">Back Home</Button>}
            />
        </Appbar>

    )
}

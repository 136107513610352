import React, { useEffect, useState } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Input, DatePicker, Radio, Form, Space, Select, Checkbox, Card } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SERVER_URL } from '../../api';
import { tokenData } from '../../constants';
import { Spinner } from 'react-bootstrap';
import { useBranch } from '../../context/BranchContext';
import useWindowDimensions from '../../constants/useWindowDimensions';
import { useSurvey } from '../../context/SurveyContext';
dayjs.extend(utc);
const { TextArea } = Input;
const { Option } = Select;

export default function CreateForm({
    selectedItems,
    setSelectedItems,
    selectedItemsDepartment,
    setSelectedItemsDepartment,
    isModalOpen,
    handleCancel,
    fetchSurveyId,
    selectedTypeId,
    setSurveyLates }) {

    const { fetchSurveyData } = useSurvey()
    const { branch } = useBranch()
    const { width } = useWindowDimensions()
    const [title, setTitle] = useState()
    const [descriptions, setDescriptions] = useState()
    const [value, setValue] = useState();
    const [currentStartDate, setCurrentStartDate] = useState(null)
    const [currentEndDate, setCurrentEndDate] = useState(null)
    const [isUsing, setIsUsing] = useState(true)
    const [isLoading, setIsLoading] = useState()
    const [departMent, setDepartMent] = useState([])

    useEffect(() => {
        if (selectedItems) {
            fetchDepartMent(selectedItems?.[0])
        }
    }, [selectedItems])

    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === "out") {
            setSelectedItems([]);
            setSelectedItemsDepartment([]);
            setIsUsing(true)
        }
    };

    const fetchDepartMent = async (id) => {
        // setIsLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/selectAll-Department-In-ABranch/" + id}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });

            setDepartMent(response?.data?.results)
            // setIsLoading(false)
        } catch (error) {
            // setIsLoading(false)
            console.log("Error: ", error)
        }
    }

    function onChangeStartDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);

        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD")
        // Set the selected date in the new time zone
        setCurrentStartDate(bangkokTime);
        // console.log("=========>parsedDate", bangkokTime.format("YYYY-MM-DD HH:mm:ss"));
    }

    function onChangeEndDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);

        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD")
        // Set the selected date in the new time zone
        setCurrentEndDate(bangkokTime);
        // console.log("=========>parsedDate", bangkokTime.format("YYYY-MM-DD HH:mm:ss"));
    }

    const onCreateForm = async (values) => {

        setIsLoading(true)

        const apiUrl = `${SERVER_URL}survey/insert`;

        const parsedDateStartDate = dayjs(currentStartDate);
        const parsedDateEndDate = dayjs(currentEndDate);
        const formattedStartDate = parsedDateStartDate.utcOffset(7).format("YYYY-MM-DD");
        const formattedEndDate = parsedDateEndDate.utcOffset(7).format("YYYY-MM-DD");

        // Map the selected branch IDs to the desired structure
        // const formattedBranchs = selectedItems.map(branchId => ({ branch_id: String(branchId) }));
        // const formattedDepartMent = selectedItemsDepartment.map(departmentId => ({ department_id: String(departmentId) }));
        // Map the selected branch IDs to the desired structure
        const formattedBranchs = selectedItems.length > 0 ? selectedItems.map(branchId => ({ branch_id: String(branchId) })) : [{ branch_id: "" }];
        const formattedDepartMent = selectedItemsDepartment.length > 0 ? selectedItemsDepartment.map(departmentId => ({ department_id: String(departmentId) })) : [{ department_id: "" }];

        const requestData = {
            ...values,
            type_id: selectedTypeId,
            title: title,
            descriptions: descriptions,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            surveys_status: value,
            is_active: "1",
            branchs: formattedBranchs,
            departments: formattedDepartMent
        };

        try {
            // Send a POST request using Axios
            axios.post(apiUrl, requestData, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                },
            })
                .then(response => {
                    setSurveyLates(prevSurvey => [response.data, ...prevSurvey]);
                    fetchSurveyId()
                    handleCancel()
                    setIsLoading(false)
                    fetchSurveyData()
                })
                .catch(error => {
                    // Handle errors
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        } catch (error) {
            console.log("Error: ", error)
            setIsLoading(false)
        }
    }

    const handleChange = (selectedItems) => {
        setSelectedItems(selectedItems);
    };

    const handleCheck = (itemValue) => {
        const currentIndex = selectedItems.indexOf(itemValue);
        const newChecked = [...selectedItems];

        if (currentIndex === -1) {
            newChecked.push(itemValue);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedItems(newChecked);
    };

    const handleChangeDepartMent = (selectedItems) => {
        setSelectedItemsDepartment(selectedItems);
    };

    const handleCheckDepartMent = (itemValue) => {
        const currentIndex = selectedItemsDepartment?.indexOf(itemValue);
        const newChecked = [...selectedItemsDepartment];

        if (currentIndex === -1) {
            newChecked.push(itemValue);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedItemsDepartment(newChecked);
    };

    const handleChangeIsUsing = (e) => {
        setIsUsing(e.target.value);

        // Reset selected items when the user selects "true" in Radio.Group
        if (e.target.value === true) {
            setSelectedItems([]);
            setSelectedItemsDepartment([]);
        }
    };

    return (
        <Modal
            title="ສ້າງເເບບຟອມ"
            open={isModalOpen}
            // onOk={onCreateType}
            transitionName={false}
            centered
            okText={false}
            footer={[]}
            width={width > 700 ? '50%' : "100%"}
            onCancel={handleCancel}>
            <Form
                layout="vertical"
                initialValues={{
                    remember: true,
                    type_name: '',
                }}
                onFinish={onCreateForm}
            >
                <div className=' pt-[10px] pb-5'>

                    <Form.Item name="title" label="ຫົວເລື່ອງ" rules={[{ required: true, message: 'ກະລຸນາປ້ອນຫົວເລື່ອງ!' }]} tooltip="ນີ້ເເມ່ນຫົວເລື່ອງ">
                        <TextArea
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            rows={4} />
                    </Form.Item>

                    <Form.Item name="descriptions" label="ລາຍລະອຽດ" rules={[{ required: true, message: 'ກະລຸນາປ້ອນລາຍລະອຽດ!' }]} tooltip="ນີ້ເເມ່ນລາຍລະອຽດ">
                        <TextArea
                            value={descriptions}
                            onChange={(e) => setDescriptions(e.target.value)}
                            rows={4} />
                    </Form.Item>

                    <div className=' flex justify-between mt-4'>
                        <div className=' w-full'>
                            <Form.Item name="start_date" label="ເລີ່ມວັນທີ" rules={[{ required: true, message: 'ກະລຸນາເລືອກວັນທີເລີ່ມ!' }]} tooltip="ນີ້ເເມ່ນວັນທີເລີ່ມ">
                                <DatePicker
                                    value={currentStartDate}
                                    onChange={onChangeStartDate}
                                    className=' w-full h-[40px]'
                                    placeholder='ເລືອກວັນທີ' />
                            </Form.Item>
                        </div>
                        <div className=' w-full'>
                            <Form.Item name="end_date" label="ຫາວັນທີ" rules={[{ required: true, message: 'ກະລຸນາເລືອກວັນທີສິ້ນສຸດ!' }]} tooltip="ນີ້ເເມ່ນວັນທີສິ້ນສຸດ">
                                <DatePicker
                                    value={currentEndDate}
                                    onChange={onChangeEndDate}
                                    className=' ms-2 w-full h-[40px]'
                                    placeholder='ເລືອກວັນທີ'
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item name="surveys_status" rules={[{ required: true, message: 'ກະລຸນາເລືອກຮູບເເບບຟອມ!' }]} tooltip="ນີ້ເເມ່ນຮູບເເບບຟອມ">
                        <Radio.Group
                            className=' mt-4'
                            onChange={onChange}
                            value={value}>
                            <Radio value={'in'}>ເເບບຟອມພາຍໃນ</Radio>
                            <Radio value={'out'}>ເເບບຟອມພາຍນອກ</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Card
                        // title="Card title"
                        bordered={false}
                        className={` w-full ${value === "out" || !value ? "hidden" : ""}`}
                        style={{
                            boxShadow: '1px 1px 5px #BEBEBE',
                        }}
                    >
                        <div className={`${value === "out" || !value ? "hidden" : ""}`}>
                            <Radio.Group
                                className=' mt-3'
                                onChange={handleChangeIsUsing}
                                value={isUsing}>
                                <Space direction="vertical" >
                                    <Radio value={true}>ທັງໜົດ</Radio>
                                    <Radio value={false}>ສະເພາະທີ່ເລຶອກ</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                        <div className={`mt-3 ${isUsing === true ? "hidden" : ""}`}>
                            <div className=' w-full'>
                                <div>ສາຂາ ({selectedItems?.length})</div>
                                <Select
                                    mode="multiple"
                                    className=" mt-3 w-full h-[auto]"
                                    placeholder="ເລືອກສາຂາ"
                                    value={selectedItems}
                                    onChange={handleChange}
                                >
                                    {branch.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            <Checkbox
                                                checked={selectedItems.includes(item.id)}
                                                onChange={() => handleCheck(item.id)}
                                            >
                                                {item?.branch_name}
                                            </Checkbox>
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='w-full mt-3' >
                                <div>ພະເເນກ ({selectedItemsDepartment?.length})</div>
                                <Select
                                    disabled={selectedItems?.length > 0 ? false : true}
                                    mode="multiple"
                                    className=" mt-3 w-full  min-h-[10px] max-h-80"
                                    placeholder="ເລືອກພະເເນກ"
                                    value={selectedItemsDepartment}
                                    onChange={handleChangeDepartMent}
                                >
                                    {departMent.map((item) => (
                                        <Option key={item.departmentId} value={item.departmentId}>
                                            <Checkbox
                                                checked={selectedItemsDepartment.includes(item.departmentId)}
                                                onChange={() => handleCheckDepartMent(item.departmentId)}
                                            >
                                                {item.department_name}
                                            </Checkbox>
                                        </Option>
                                    ))}
                                </Select>

                            </div>
                        </div>
                    </Card>
                </div>
                <div className=' flex justify-end'>
                    <Button
                        onClick={() => handleCancel()}
                        className=' h-[40px]'
                        // type="primary"
                        icon={<CloseOutlined />}>
                        ຍົກເລິກ
                    </Button>
                    <Button
                        htmlType="submit"
                        className=' bg-blue-600 h-[40px] ms-2'
                        type="primary"
                        icon={<CheckOutlined />}>
                        {isLoading ? <Spinner size='sm' /> : "ບັນທືກ"}
                    </Button>
                </div>
            </Form>
        </Modal >
    )
}

import React, { useState, useEffect } from 'react'
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Result, Radio, Button, List, Card, Pagination } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useChoice } from '../../context/ChoiceContext';
import { onConfirmButton } from '../../constants';
import { successSwal } from '../../constants/sweetalert';
import { SERVER_URL } from '../../api';
import axios from 'axios';
import { useQuestions } from '../../context/QuestionContext';
import { Spinner } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import UpdateQuestion from './UpdateQuestion';
import { useType } from '../../context/TypeContext';
import EditSurveyCreateQuestion from './EditSurveyCreateQuestion';
import EditSurveyUpdateQuestion from './EditSurveyUpdateQuestion';

export default function EditSurveyQuestionList({ contextHolder, selectedTypeId, messageApi }) {

    const { id } = useParams()

    const surveyId = id

    const { choices } = useChoice()
    const { types } = useType()
    const [createQuestion, setCreateQuestion] = useState(false)
    const { questions, setQuestions, loading, fetchquestionsData } = useQuestions()
    const [Delete, setDelete] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Set your desired page size
    const [updateQuestion, setUpdateQuestion] = useState(false)
    const [selectedUpdateQuestion, setSelectUpdateQuestion] = useState()

    const [trueChoice, setTrueChoice] = useState()
    const [trueQuestion, setTrueQuestion] = useState()

    const questionData = questions?.filter((id) => id?.survey_id === surveyId)
    const typeStatusData = types?.filter((id) => id?.type_id === selectedTypeId)

    const getChoicesForQuestion = (questionId) => {
        return choices.filter(choice => choice?.question_id === questionId);
    };

    // Calculate the start and end index based on the current page and page size
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Slice the questions data to show only the questions for the current page
    const paginatedQuestionData = questionData?.slice(startIndex, endIndex);

    const handleShowDelete = (id, question_text) => {
        setDelete({ question_text, id });
        onConfirmButton('ທ່ານຕອ້ງການລຶບເເທ້ ຫຼື ບໍ່ ?', () => onDeleteQuestion(id))
    };

    const onDeleteQuestion = (id) => {

        const apiUrl = `${SERVER_URL}survey/question/delete-survey-question/${id}`

        try {
            axios.delete(apiUrl)
                .then(response => {
                    fetchquestionsData()
                    successSwal('ລຶບສຳເລັດ')
                })
                .catch(error => {
                    console.error('Error deleting survey', error);
                    successSwal('ມີຂໍ້ຜິດພາດ ລຶບບໍ່ສຳເລັດ')
                });
        } catch (error) {
            console.log("Error: ", error)
        }

    }

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
    };

    const handleRadioChange = async (e, questionId) => {

        const selectedChoiceId = e.target.value;

        // Find the choice based on the selectedChoiceId
        const selectedChoice = getChoicesForQuestion(questionId)?.find(choice => choice.choice_id === selectedChoiceId);

        // Update both states
        setTrueChoice(selectedChoiceId);
        setTrueQuestion(selectedChoice?.question_id);
        // Insert the true choice immediately
        // await onCreateTrueChoice();
    };


    // console.log("===============>edit question", surveyId)

    return (
        <div>
            {contextHolder}
            <Card
                bordered={false}
            >
                <div className=' max-sm:flex-col flex  items-center'>
                    <Button
                        onClick={() => setCreateQuestion(true)}
                        className=' max-sm:w-full bg-blue-500 h-[40px]'
                        type="primary"
                        icon={<PlusOutlined />}>
                        ເພີ່ມຄຳຖາມ
                    </Button>
                    <div className=' max-sm:mt-3 lg:ms-2 text-[14px]'>
                        <Pagination
                            current={currentPage}
                            total={questionData?.length}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                        />
                    </div>
                    <div className='max-sm:mt-3 md:ms-5 lg:ms-5 text-[16px]'>ລວມທັງໜົດ {questionData?.length} ຂໍ້</div>
                </div>
            </Card>

            <Card className='mt-3' bordered={false} >
                <List
                    itemLayout="horizontal"
                    dataSource={paginatedQuestionData}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                // title={<div className=' text-[18px]'>{index + 1 + pageSize * (currentPage - 1)}.&ensp;{item?.question_text}</div>}
                                title={<div>
                                    <div className=' max-sm:flex-col  flex justify-between'>
                                        <div className=' text-[18px] max-sm:w-full w-[80%] font-bold'>{index + 1 + pageSize * (currentPage - 1)}.&ensp;{item?.question_text}</div>
                                        <div className=' max-sm:mt-3'>
                                            <Button
                                                onClick={() => {
                                                    setUpdateQuestion(true)
                                                    setSelectUpdateQuestion(item)
                                                }}
                                                className=' bg-green-900'
                                                type="primary"
                                                icon={<EditOutlined />}>
                                                ເເກ້ໄຂ
                                            </Button>
                                            <Button
                                                onClick={() => handleShowDelete(item?.question_id)}
                                                className=' bg-red-600 ms-2'
                                                type="primary"
                                                icon={<DeleteOutlined />}>
                                                ລຶບ
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={` ${!item?.images ? " hidden" : "mt-3"} `}><img width={'100%'} src={item?.images} style={{ height: 250, objectFit: "cover" }} /></div>
                                </div>}
                                description={<div className=' mt-3'>
                                    {
                                        <Radio.Group>
                                            {getChoicesForQuestion(item?.question_id).map(choice => (
                                                <div key={choice.id}>
                                                    <Radio className='p-2' value={choice?.choice_id} onChange={(e) => handleRadioChange(e, item.question_id)} >  {choice.choice_text}</Radio>
                                                </div>
                                            ))}
                                        </Radio.Group>
                                    }
                                </div>}
                            />

                        </List.Item>
                    )}
                />
            </Card>
            <EditSurveyCreateQuestion
                isModalOpen={createQuestion}
                handleCancel={() => setCreateQuestion(false)}
                messageApi={messageApi}
            />
            <EditSurveyUpdateQuestion
                isModalOpen={updateQuestion}
                handleCancel={() => setUpdateQuestion(false)}
                selectedUpdateQuestion={selectedUpdateQuestion}
            />
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import { PlusCircleOutlined, EditOutlined, DeleteOutlined, CopyOutlined, FieldTimeOutlined, CloseCircleOutlined, LineChartOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Card, Tag, Skeleton, Tabs, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSurvey } from '../../context/SurveyContext';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { SERVER_URL } from '../../api';
import { successSwal } from '../../constants/sweetalert';
import { onConfirmButton } from '../../constants';
import Appbar from '../../components/Appbar';

const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const { Meta } = Card;

export default function SurveyList() {

    const navigate = useNavigate()
    const [activeKey, setActiveKey] = useState(localStorage.getItem('activeSurveyStatus') || '1');
    const [Delete, setDelete] = useState("");
    const [loadingDelete, setLoadingDelete] = useState(false)
    const { survey, surveyInActive, surveyOutAcive, surveyInNotAcive, surveyOutNotAcive, fetchSurveyData, loading } = useSurvey()

    useEffect(() => {
        localStorage.setItem('activeSurveyStatus', activeKey);
    }, [activeKey]);

    const handleTabSelect = (key) => {
        setActiveKey(key);
    };

    const handleShowDelete = (id, name) => {
        setDelete({ name, id });
        onConfirmButton('ທ່ານຕອ້ງການລຶບເເທ້ ຫຼື ບໍ່ ?', () => onDeleteSurvey(id))
    };

    const onDeleteSurvey = (surveyId) => {
        setLoadingDelete(true)
        const apiUrl = `${SERVER_URL}survey/delete/${surveyId}`
        try {

            axios.delete(apiUrl)
                .then(response => {
                    // console.log('Survey deleted successfully', response.data);
                    successSwal('ລຶບສຳເລັດ')
                    fetchSurveyData()
                    setLoadingDelete(false)
                })
                .catch(error => {
                    console.error('Error deleting survey', error);
                    successSwal('ມີຂໍ້ຜິດພາດ ລຶບບໍ່ສຳເລັດ')
                    setLoadingDelete(false)
                    // Handle errors, show a message, etc.
                });

        } catch (error) {
            console.log("Error: ", error)
            setLoadingDelete(false)
        }

    }

    const setCookie = (name, value, days) => {
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = `expires=${expirationDate.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/`;
    };

    const handleButtonClick = async () => {
        // setIsLoading(true);
        try {

            const response = await fetch(`${SERVER_URL}login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: "admin",
                    password: "123456",
                }),
            });

            if (!response.ok) {
                throw new Error('Authentication failed');
            }

            const data = await response.json();

            setCookie('user', JSON.stringify(data));

        } catch (error) {
            console.error('Login error', error);
        } finally {
            // setIsLoading(false);
        }
    };

    const onEditSurvey = (id) => {
        navigate("/edit-survey/" + id);
    }

    const onDetailSurvey = (id) => {
        navigate("/survey-detail/" + id);
    }

    const onDetailSurveyIn = (id) => {
        navigate("/survey-detail-in/" + id);
    }

    const calculateDaysDifference = (advertEndDate) => {
        const endDate = moment(advertEndDate);
        const now = moment();

        // Calculate the difference in days
        const days = endDate.diff(now, "days");

        // Calculate the difference in hours (excluding full days)
        const hours = endDate.subtract(days, "days").diff(now, "hours");

        // Calculate the difference in minutes (excluding full days and full hours)
        const minutes = endDate.subtract(hours, "hours").diff(now, "minutes");

        return { days, hours, minutes };
    };

    // const calculateDaysDifference = (advertEndDate) => {
    //     const endDate = dayjs(advertEndDate);
    //     const now = dayjs();

    //     // Calculate the difference in days
    //     const days = endDate.diff(now, 'day');

    //     // Calculate the difference in hours (excluding full days)
    //     const hours = endDate.subtract(days, 'day').diff(now, 'hour');

    //     // Calculate the difference in minutes (excluding full days and full hours)
    //     const minutes = endDate.subtract(hours, 'hour').diff(now, 'minute');

    //     return { days, hours, minutes };
    // };

    const convertStillTime = (pro) => {
        const timeDiffStart = calculateDaysDifference(pro?.start_date);

        if (timeDiffStart.minutes >= 0) {
            return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-red-600 text-[20px]' />} > <div className=' text-red-600 font-bold' > ຍັງບໍ່ຮອດເວລາ </div></Tag>;
        }

        const timeDiff = calculateDaysDifference(pro?.end_date);

        if (timeDiff.days > 0) {
            return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-green-800  text-[20px]' />} > <div className=' text-green-800 font-bold'> ເຫຼືອເວລາ {`${timeDiff.days} ມື້ ${timeDiff.hours} ຊົ່ວໂມງ ${timeDiff.minutes} ນາທີ`} </div></Tag>
        }
        if (timeDiff.hours > 0) {
            return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-green-800  text-[20px]' />} ><div>ເຫຼືອເວລາ {`${timeDiff.hours} ຊົ່ວໂມງ ${timeDiff.minutes} ນາທີ`}</div> </Tag>;
        }
        if (timeDiff.minutes >= 0) {
            return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-green-800  text-[20px]' />} > <div> ເຫຼືອເວລາ {`${timeDiff.minutes} ນາທີ`} </div> </Tag>;
        }
        return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-red-600 text-[20px]' />} >  <div className=' text-red-600 font-bold'>ໝົດອາຍຸ</div> </Tag>;
    }

    const onAnswer = (id) => {
        const newTab = window.open("/forms/" + id, "_blank");
        if (newTab) {
            newTab.focus();
        } else {
            // Handle this case as needed
            console.error('Unable to open new tab. Please allow pop-ups for this website.');
        }
    }

    const items = [
        {
            key: '1',
            label: <span>ເເບບຟອມພາຍໃນ ({surveyInActive?.length})</span>,
            children:
                <div className='max-md:mt-2 md:mt-2 max-lg:mt-2 lg:mt-2'>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2 pt-3 pb-3">

                        {Array?.from({ length: 3 }, (_, index) => (
                            <Skeleton
                                className='w-full'
                                key={index}  // Make sure to add a unique key for each Skeleton
                                active
                                loading={loading}
                                paragraph={{ rows: 6 }}
                            />
                        ))}

                        {surveyInActive?.map((e) => (
                            <Card
                                actions={[
                                    <Button className='border-none' onClick={() => onEditSurvey(e?.surveys_id)} icon={<EditOutlined />}>ເເກ້ໄຂ</Button>,
                                    <Button className='border-none' onClick={() => onDetailSurveyIn(e?.surveys_id)} icon={<LineChartOutlined />}>ລາຍລະອຽດ</Button>,
                                    <Button className='border-none' onClick={() => handleShowDelete(e?.surveys_id)} icon={<DeleteOutlined />}>ລຶບ</Button>
                                ]}
                                className='  shadow-sm hover:shadow-lg  shadow-slate-900 '
                            >
                                <Meta
                                    // avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                    title={
                                        <div className=' flex justify-between'>
                                            <div>
                                                {_.truncate(e?.title, {
                                                    length: 35,
                                                })}
                                            </div>
                                        </div>
                                    }
                                    description={
                                        <div className=' text-[16px]'>
                                            <div>{e?.surveys_status === "out" ? "ເເບບຟອມພາຍນອກ" : "ເເບບຟອມພາຍໃນ"}</div>
                                            <div>
                                                <span>ເລີ່ມວັນທີ {moment(e?.start_date).format("DD-MM-YYYY")}</span>
                                                <span className=' ms-2'>ຫາວັນທີ {moment(e?.end_date).format("DD-MM-YYYY")}</span>
                                            </div>
                                            <div> {convertStillTime(e)}</div>
                                            <div className=' mt-3'>
                                                <Tag
                                                    className={` ${e?.is_active === 1 ? "bg-green-600" : " bg-red-600"} h-7 flex justify-center items-center w-25`}
                                                    color={e?.is_active === 1 ? "#3b5999" : "#cd201f"}>
                                                    {e?.is_active === 1 ? <div><CheckCircleOutlined className='mr-2' /> ໃຊ້ງານ</div> : <div><CloseCircleOutlined className='mr-2' /> ບໍ່ໄດ້ໃຊ້ງານ</div>}
                                                </Tag>
                                            </div>
                                        </div>
                                    }
                                />
                            </Card>
                        ))}
                    </div>
                    {
                        !loading && surveyInActive?.length <= 0 && (
                            <Result
                                status="warning"
                                title="ຍັງບໍ່ມີຂໍ້ມູນເທື່ອ !"
                            />
                            // <Empty />
                        )
                    }
                </div>,
        },
        {
            key: '2',
            label: <span>ເເບບຟອມພາຍນອກ ({surveyOutAcive?.length})</span>,
            children:
                <div className='max-md:mt-2 md:mt-2 max-lg:mt-2 lg:mt-2'>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2 pt-3 pb-3">

                        {Array?.from({ length: 3 }, (_, index) => (
                            <Skeleton
                                className='w-full'
                                key={index}  // Make sure to add a unique key for each Skeleton
                                active
                                loading={loading}
                                paragraph={{ rows: 6 }}
                            />
                        ))}

                        {surveyOutAcive?.map((e) => (
                            <Card
                                actions={[
                                    <Button className='border-none' onClick={() => onEditSurvey(e?.surveys_id)} icon={<EditOutlined />}>ເເກ້ໄຂ</Button>,
                                    <Button className='border-none' onClick={() => onDetailSurvey(e?.surveys_id)} icon={<LineChartOutlined />}>ລາຍລະອຽດ</Button>,
                                    <Button className='border-none' onClick={() => handleShowDelete(e?.surveys_id)} icon={<DeleteOutlined />}>ລຶບ</Button>
                                ]}
                                className='  shadow-sm hover:shadow-lg  shadow-slate-900 '
                            >
                                <Meta
                                    // avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                    title={
                                        <div className=' flex justify-between'>
                                            <div>
                                                {_.truncate(e?.title, {
                                                    length: 35,
                                                })}
                                            </div>
                                            <div onClick={() => onAnswer(e?.surveys_id)} className=' text-blue-600 cursor-pointer'> ກອັບລີ້ງ <CopyOutlined className=' text-[20px]' /></div>
                                        </div>
                                    }
                                    description={
                                        <div className=' text-[16px]'>
                                            <div>{e?.surveys_status === "out" ? "ເເບບຟອມພາຍນອກ" : "ເເບບຟອມພາຍໃນ"}</div>
                                            <div>
                                                <span>ເລີ່ມວັນທີ {moment(e?.start_date).format("YYYY-MM-DD")}</span>
                                                <span className=' ms-2'>ຫາວັນທີ {moment(e?.end_date).format("YYYY-MM-DD")}</span>
                                            </div>
                                            <div>{convertStillTime(e)}</div>
                                            <div className=' mt-3'>
                                                <Tag
                                                    className=' h-7 flex justify-center items-center w-25'
                                                    color={e?.is_active === 1 ? "#3b5999" : "#cd201f"}>
                                                    {e?.is_active === 1 ? <div><CheckCircleOutlined className='mr-2' /> ໃຊ້ງານ</div> : <div><CloseCircleOutlined className='mr-2' /> ບໍ່ໄດ້ໃຊ້ງານ</div>}
                                                </Tag>
                                            </div>
                                        </div>
                                    }
                                />
                            </Card>
                        ))}
                    </div>
                    {
                        !loading && surveyOutAcive?.length <= 0 && (
                            <Result
                                status="warning"
                                title="ຍັງບໍ່ມີຂໍ້ມູນເທື່ອ !"
                            />
                            // <Empty />
                        )
                    }
                </div>,
        },
        {
            key: '3',
            label: <span>ເເບບຟອມພາຍໃນໝົດກຳນົດ ({surveyInNotAcive?.length})</span>,
            children:
                <div className='max-md:mt-2 md:mt-2 max-lg:mt-2 lg:mt-2'>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2 pt-3 pb-3">

                        {Array?.from({ length: 3 }, (_, index) => (
                            <Skeleton
                                className='w-full'
                                key={index}  // Make sure to add a unique key for each Skeleton
                                active
                                loading={loading}
                                paragraph={{ rows: 6 }}
                            />
                        ))}

                        {surveyInNotAcive?.map((e) => (
                            <Card
                                actions={[
                                    <Button className='border-none' onClick={() => onEditSurvey(e?.surveys_id)} icon={<EditOutlined />}>ເເກ້ໄຂ</Button>,
                                    <Button className='border-none' onClick={() => onDetailSurveyIn(e?.surveys_id)} icon={<LineChartOutlined />}>ລາຍລະອຽດ</Button>,
                                    <Button className='border-none' onClick={() => handleShowDelete(e?.surveys_id)} icon={<DeleteOutlined />}>ລຶບ</Button>
                                ]}
                                className='  shadow-sm hover:shadow-lg  shadow-slate-900 '
                            >
                                <Meta
                                    // avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                    title={
                                        <div className=' flex justify-between'>
                                            <div>
                                                {_.truncate(e?.title, {
                                                    length: 35,
                                                })}
                                            </div>
                                        </div>
                                    }
                                    description={
                                        <div className=' text-[16px]'>
                                            <div>{e?.surveys_status === "out" ? "ເເບບຟອມພາຍນອກ" : "ເເບບຟອມພາຍໃນ"}</div>
                                            <div>
                                                <span>ເລີ່ມວັນທີ {moment(e?.start_date).format("YYYY-MM-DD")}</span>
                                                <span className=' ms-2'>ຫາວັນທີ {moment(e?.end_date).format("YYYY-MM-DD")}</span>
                                            </div>
                                            <div>{convertStillTime(e)}</div>
                                            <div className=' mt-3'>
                                                <Tag
                                                    className=' h-7 flex justify-center items-center w-25'
                                                    color={e?.is_active === 1 ? "#3b5999" : "#cd201f"}>
                                                    {e?.is_active === 1 ? <div><CheckCircleOutlined className='mr-2' /> ໃຊ້ງານ</div> : <div><CloseCircleOutlined className='mr-2' /> ບໍ່ໄດ້ໃຊ້ງານ</div>}
                                                </Tag>
                                            </div>
                                        </div>
                                    }
                                />
                            </Card>
                        ))}
                    </div>
                    {
                        !loading && surveyInNotAcive?.length <= 0 && (
                            <Result
                                status="warning"
                                title="ຍັງບໍ່ມີຂໍ້ມູນເທື່ອ !"
                            />
                            // <Empty />
                        )
                    }
                </div>,
        },
        {
            key: '4',
            label: <span>ເເບບຟອມພາຍນອກໝົດກຳນົດ ({surveyOutNotAcive?.length})</span>,
            children:
                <div className='max-md:mt-2 md:mt-2 max-lg:mt-2 lg:mt-2'>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2 pt-3 pb-3">

                        {Array?.from({ length: 3 }, (_, index) => (
                            <Skeleton
                                className='w-full'
                                key={index}  // Make sure to add a unique key for each Skeleton
                                active
                                loading={loading}
                                paragraph={{ rows: 6 }}
                            />
                        ))}

                        {surveyOutNotAcive?.map((e) => (
                            <Card
                                actions={[
                                    <Button className='border-none' onClick={() => onEditSurvey(e?.surveys_id)} icon={<EditOutlined />}>ເເກ້ໄຂ</Button>,
                                    <Button className='border-none' onClick={() => onDetailSurvey(e?.surveys_id)} icon={<LineChartOutlined />}>ລາຍລະອຽດ</Button>,
                                    <Button className='border-none' onClick={() => handleShowDelete(e?.surveys_id)} icon={<DeleteOutlined />}>ລຶບ</Button>
                                ]}
                                className='  shadow-sm hover:shadow-lg  shadow-slate-900 '
                            >
                                <Meta
                                    // avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                    title={
                                        <div className=' flex justify-between'>
                                            <div>
                                                {_.truncate(e?.title, {
                                                    length: 35,
                                                })}
                                            </div>
                                        </div>
                                    }
                                    description={
                                        <div className=' text-[16px]'>
                                            <div>{e?.surveys_status === "out" ? "ເເບບຟອມພາຍນອກ" : "ເເບບຟອມພາຍໃນ"}</div>
                                            <div>
                                                <span>ເລີ່ມວັນທີ {moment(e?.start_date).format("YYYY-MM-DD")}</span>
                                                <span className=' ms-2'>ຫາວັນທີ {moment(e?.end_date).format("YYYY-MM-DD")}</span>
                                            </div>
                                            <div>{convertStillTime(e)}</div>
                                            <div className=' mt-3'>
                                                <Tag
                                                    className=' h-7 flex justify-center items-center w-25'
                                                    color={e?.is_active === 1 ? "#3b5999" : "#cd201f"}>
                                                    {e?.is_active === 1 ? <div><CheckCircleOutlined className='mr-2' /> ໃຊ້ງານ</div> : <div><CloseCircleOutlined className='mr-2' /> ບໍ່ໄດ້ໃຊ້ງານ</div>}
                                                </Tag>
                                            </div>
                                        </div>
                                    }
                                />
                            </Card>
                        ))}
                    </div>
                    {
                        !loading && surveyOutNotAcive?.length <= 0 && (
                            <Result
                                status="warning"
                                title="ຍັງບໍ່ມີຂໍ້ມູນເທື່ອ !"
                            />
                            // <Empty />
                        )
                    }
                </div>,
        }
    ];

    return (
        <Appbar title={<div>ເເບບຟອມທັງໝົດ</div>}>
            <div className=' max-sm:pl-[20px] max-sm:pr-[20px] md:p-0 max-md:p-0 max-lg:p-0 lg:p-0  '>
                <Card bordered={false} className=' max-sm:mt-3 shadow-md  shadow-gray-900' >
                    <div className='  max-sm:flex-col flex  text-[18px] justify-between items-center '>
                        <div className=' max-sm:hidden'>ເເບບຟອມທັງໝົດ</div>
                        {/* <div className=' max-sm:hidden'>ເເບບຟອມທັງໝົດ ( {survey?.length} ) ລາຍການ</div> */}
                        <div className=' sm:w-[100%] max-sm:w-[100%] md:w-[auto]'>
                            <Button
                                type="primary"
                                onClick={() => navigate("/add-survey")}
                                className=' h-[40px] bg-blue-600 text-[#FFFFFF] sm:w-[100%] max-sm:w-[100%]'
                                icon={<PlusCircleOutlined />}>
                                ສ້າງເເບບຟອມ
                            </Button>
                            {/* <Button
                                type="primary"
                                onClick={() => handleButtonClick()}
                                className='h-10  bg-blue-600 text-[#FFFFFF] sm:w-[100%] max-sm:w-[100%]'
                                icon={<PlusCircleOutlined />}>
                                login
                            </Button> */}
                        </div>
                    </div>
                </Card>
                <Tabs
                    onChange={handleTabSelect}
                    activeKey={activeKey}
                    className='mt-3'
                    type="card"
                    defaultActiveKey="1" items={items}
                />
            </div>
        </Appbar>
    )
}

import React, { useState, useEffect } from 'react';
import { CloseOutlined, CheckOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Form, Select, message, Upload, List, Typography } from 'antd';
import axios from 'axios';
import { tokenData } from '../../api/auth';
import { useType } from '../../context/TypeContext';
import { useCategory } from '../../context/CategoryContext';
import { SERVER_URL } from '../../api';
import { useChoice } from '../../context/ChoiceContext';
import { useQuestions } from '../../context/QuestionContext';
import { Spinner } from 'react-bootstrap';
import { NumberFormatBase } from 'react-number-format';
const { TextArea } = Input;
export default function UpdateQuestion({ isModalOpen, handleCancel, surveyId, surveyLates, selectedUpdateQuestion, setSurveyId, setCreateCategory }) {

    const { categorys } = useCategory()
    const { fetchchoicesData } = useChoice()
    const { questions, setQuestions, fetchquestionsData } = useQuestions()
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [choiceType, setChoiceType] = useState(1)
    const [choices, setChoices] = useState([]);
    const [categoryId, setCategoryId] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [updateQuestionData, setUpdateQuestionData] = useState()
    const [question_text, setQuestion_text] = useState()
    const [fileName, setFileName] = useState([])
    const [file_name, setFile_name] = useState()
    const [customChoiceText, setCustomChoiceText] = useState('');
    const [customScores, setCustomScores] = useState('');


    useEffect(() => {
        if (updateQuestionData || updateQuestionData?.choice || updateQuestionData?.results || updateQuestionData?.results?.length > 0) {
            setQuestion_text(updateQuestionData?.results?.[0]?.question_text);
            setCategoryId(updateQuestionData?.results?.[0]?.category_id);
            setImageUrl(updateQuestionData?.results?.[0]?.file_name);
            setChoiceType(updateQuestionData?.choice?.[0]?.choice_type);
            // setChoiceType('2');

            // Extract and map choices from updateQuestionData
            const choicesData = updateQuestionData?.choice;
            if (choicesData && choicesData?.length > 0) {
                const mappedChoices = choicesData?.map(choices => ({
                    choice_text: choices?.choice_text || '',
                    scores: choices?.scores || ''
                }));
                setChoices(mappedChoices);
            }
        }
    }, [updateQuestionData]);

    const fectUpdateQuestion = async () => {
        try {
            // Set loading to true before making the request
            setLoading(true);

            const response = await axios.get(`${SERVER_URL + "survey/question/get-question/" + selectedUpdateQuestion?.question_id}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });

            // Update state with the fetched data
            setUpdateQuestionData(response?.data);
        } catch (error) {
            console.log("Error: ", error);
            // Handle errors here, e.g., set an error state
        } finally {
            // Set loading to false after the request completes (whether successful or not)
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedUpdateQuestion) {
            fectUpdateQuestion();
        }
    }, [selectedUpdateQuestion]);

    useEffect(() => {
        if (surveyLates) {
            setSurveyId(surveyLates?.[0]?.valuses?.[0]);
        }
    }, [surveyLates]);



    useEffect(() => {
        // Set choices based on the selected option
        switch (choiceType) {
            case 1:
                setChoices([
                    { choice_text: "Yes", scores: "" },
                    { choice_text: "No", scores: "" }
                ]);
                break;
            case 2:
                // setChoices(customChoiceText.trim() !== '' ? [{ choice_text: customChoiceText, scores: customScores }] : []);
                break;
            case 3:
                setChoices([
                    { choice_text: "Yes", scores: "" },
                    { choice_text: "Maybe", scores: "" },
                    { choice_text: "No", scores: "" }
                ]);
                break;
            case 4:
                setChoices([
                    { choice_text: "True", scores: "" },
                    { choice_text: "False", scores: "" }
                ]);
                break;
            default:
                setChoices([]);
        }
    }, [choiceType]);


    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const fileInputRef = React.createRef();

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFileName(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }

        // console.log("selectedFile=========>", selectedFile)
    };

    const handleButtonClickUploadImage = () => {
        // Programmatically trigger click on the hidden file input
        fileInputRef.current.click();
    };



    const onUpdateQuestion = async (values) => {

        setIsLoading(true)

        const formData = new FormData();

        const transformedChoices = choices.map((e) => ({
            choice_text: e.choice_text,
            scores: parseFloat(
                e?.scores
                    ? e?.scores.toString().replace(/,/g, "")
                    : 0
            )
        }));

        formData.append('file_name', fileName);
        // Include other form data as needed
        formData.append('surveyId', surveyId);
        formData.append('question_text', question_text);
        formData.append('categoryId', categoryId || "");
        formData.append('choice_type', choiceType);
        formData.append('choices', JSON.stringify(transformedChoices));
        try {

            // const transformedChoices = choices.map((e) => ({
            //     choice_text: e.choice_text,
            //     scores: parseFloat(
            //         e?.scores
            //             ? e?.scores.toString().replace(/,/g, "")
            //             : 0
            //     )
            // }));

            // const data = {
            //     surveyId: surveyId,
            //     categoryId: categoryId,
            //     question_text: question_text,
            //     file_name: "", // assuming file_name can be optional
            //     choice_type: choiceType || "", // assuming choice_type can be optional
            //     choices: transformedChoices || [], // assuming choices can be optional
            // };

            const url = `${SERVER_URL}survey/question/update-survey-question/${selectedUpdateQuestion?.question_id}`;
            const response = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });

            fetchchoicesData()
            handleCancel()
            fetchquestionsData()
            setIsLoading(false)

        } catch (error) {
            console.log("Error: ", error)
        }

    }

    const handleAddChoice = () => {
        // Add a new choice only if both customChoiceText and customScores are not empty
        if (customChoiceText.trim() !== '' && customScores.trim() !== '') {
            setChoices([...choices, { choice_text: customChoiceText, scores: customScores }]);

            // Clear the custom inputs for the next entry
            setCustomChoiceText('');
            setCustomScores('');
        }
    };

    const handleDeleteChoice = (index) => {
        // Create a copy of the choices array and remove the chosen item
        const updatedChoices = [...choices];
        updatedChoices.splice(index, 1);
        setChoices(updatedChoices);
    };

    const handleChangeDiscount = (e) => {
        const { value } = e.target;

        // Ensure that the input value is a valid number
        const parsedValue = parseFloat(value);

        if (isNaN(parsedValue)) {
            // Not a valid number, so clear the field
            setCustomScores("");
        } else {
            // Valid number, clamp it between 1 and 100
            const clampedValue = Math.min(10, Math.max(1, parsedValue));
            setCustomScores(clampedValue.toString());
        }
    };

    return (
        <Modal
            title="ເເກ້ໄຂຄໍາຖາມ"
            open={isModalOpen}
            // onOk={onCreateType}
            width={'60%'}
            transitionName={false}
            centered
            closeIcon={handleCancel}
            closable={false}
            okText={false}
            footer={[]}
        // onCancel={handleCancel}
        >
            <Form
                layout="vertical"
                initialValues={{
                    remember: true,
                    surveyId: surveyId,
                    categoryId: categoryId,
                    question_text: question_text,
                    file_name: '',
                    choice_type: choiceType,
                    choices: choices
                }}
                onFinish={onUpdateQuestion}
            >
                <div className='flex pt-3 pb-5 h-[auto] '>
                    <div className='w-[50%]'>

                        <Form.Item
                            label="ເລຶອກໝວດໝູ່" required
                            tooltip={'Tooltip with customize icon'}
                        >
                            <div className=' flex'>
                                <Select
                                    defaultValue=""
                                    className='h-[40px]'
                                    onChange={(value) => setCategoryId(value)}
                                    value={categoryId}
                                    options={categorys?.map((category) => ({
                                        value: category.category_id,  // Replace with the actual value from your category object
                                        label: category.category_name,  // Replace with the actual label from your category object
                                    }))}
                                />
                                <Button onClick={() => setCreateCategory(true)} className=' h-[40px] ms-2' icon={<PlusOutlined />}>
                                    ເພີ່ມໃໜ່
                                </Button>
                            </div>
                        </Form.Item>

                        <Form.Item label="ຫົວຂໍ້" rules={[{ required: true, message: 'ກະລຸນາປ້ອນຫົວຂໍ້!' }]} tooltip="ນີ້ເເມ່ນຫົວຂໍ້ຄຳຖາມ">
                            <TextArea
                                onChange={(e) => setQuestion_text(e.target.value)}
                                value={question_text}
                                rows={4} />
                        </Form.Item>


                        <div>ເພີ່ມຮູບພາບປະກອບ</div>
                        <Button
                            type="primary"
                            className='bg-blue-500 mt-2'
                            icon={<UploadOutlined />}
                            onClick={handleButtonClickUploadImage}
                        >
                            ອັບໂຫຼດຮູບພາບ
                        </Button>
                        <input
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        {imageUrl && (
                            <div className=' relative'>
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                        marginTop: '10px',
                                    }}
                                />
                                <div className=' absolute top-[-10px] right-[-5px]'>
                                    <Button
                                        icon={<CloseOutlined />}
                                        onClick={() => setImageUrl(null)}
                                        className=' bg-red-400'
                                        type="primary"
                                        shape="circle" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className=' ms-3 w-[50%]'>
                        <Form.Item
                            label="ເລຶອກປະເພດຄຳຕອບ" required
                            tooltip={'Tooltip with customize icon'}
                        >
                            <Select
                                defaultValue={1}
                                className=' h-[40px]'
                                value={choiceType}
                                onChange={(value) => setChoiceType(value)}
                                options={[
                                    { value: 1, label: 'Yes Or No' },
                                    { value: 2, label: 'Score' },
                                    { value: 3, label: 'Yes Or Maybe Or No' },
                                    { value: 4, label: 'True Or False' }
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label="ຄຳຕອບ"
                            tooltip={'Tooltip with customize icon'}
                        >
                            <List
                                bordered
                                dataSource={choices}
                                renderItem={(item, index) => (
                                    <List.Item
                                        key={index}
                                        actions={[
                                            <a key="list-loadmore-more">{item?.scores}</a>,
                                            <a key="list-loadmore-more" onClick={() => handleDeleteChoice(index)}>
                                                ລຶບ
                                            </a>
                                        ]}
                                    >
                                        <div> <Typography.Text mark>[{index + 1}]</Typography.Text> {item?.choice_text}</div>
                                    </List.Item>

                                )}
                            />
                        </Form.Item>
                        {choiceType === 2 && (
                            <>
                                <Input
                                    placeholder="ປ້ອນຄຳຕອບ"
                                    value={customChoiceText}
                                    onChange={(e) => setCustomChoiceText(e.target.value)}
                                    className='mt-2'
                                />
                                <NumberFormatBase
                                    name="discount"
                                    onChange={handleChangeDiscount}
                                    value={customScores}
                                    maxLength={2}
                                    className="form-control mt-2"
                                    placeholder="ປ້ອນຄະເເນນ..."
                                    style={{ fontSize: 14, height: 40, }} />
                                <Button className='h-[40px] mt-2' icon={<PlusOutlined />} onClick={handleAddChoice}>
                                    ເພີ່ມຄຳຕອບ
                                </Button>
                            </>
                        )}

                    </div>
                </div>
                <div className=' flex justify-end'>
                    <Button
                        onClick={() => handleCancel()}
                        className=' h-[40px]'
                        // type="primary"
                        icon={<CloseOutlined />}>
                        ຍົກເລິກ
                    </Button>
                    <Button
                        htmlType="submit"
                        className=' bg-blue-600 h-[40px] ms-2'
                        type="primary"
                        icon={<CheckOutlined />}>
                        {isLoading ? <Spinner size='sm' /> : "ບັນທືກ"}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

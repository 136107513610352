import React, { createContext, useContext, useEffect, useState } from 'react';
import { tokenData } from '../constants';
import { SERVER_URL } from '../api';
import axios from 'axios';

const SurveyContext = createContext()

export default function SurveyProvider({ children }) {

    const [survey, setSurvey] = useState([]);
    const [surveyInActive, setSurveyInActive] = useState([])
    const [surveyOutAcive, setSurveyOutAcive] = useState([])
    const [surveyInNotAcive, setSurveyInNotAcive] = useState([])
    const [surveyOutNotAcive, setSurveyOutNotAcive] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchSurveyData()
    }, [])

    const fetchSurveyData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/surveys"}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setSurvey(response?.data?.SurveyInIs_Active);
            setSurveyInActive(response?.data?.SurveyInIs_Active);
            setSurveyOutAcive(response?.data?.SurveyOutIs_Active);
            setSurveyInNotAcive(response?.data?.SurveyIn_Is_NotActive);
            setSurveyOutNotAcive(response?.data?.SurveyOut_Is_NotActive);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    return (
        <SurveyContext.Provider value={{
            surveyInActive,
            surveyOutAcive,
            surveyInNotAcive,
            surveyOutNotAcive,
            survey,
            setSurvey,
            fetchSurveyData,
            loading
        }} >
            {children}
        </SurveyContext.Provider>
    )
}

export function useSurvey() {
    return useContext(SurveyContext)
}

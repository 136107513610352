import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Card, Breadcrumb, Skeleton, Table, Alert, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Appbar from '../../components/Appbar';
import axios from 'axios';
import { SERVER_URL } from '../../api';
import { tokenData } from '../../constants';
import { useQuestions } from '../../context/QuestionContext';

export default function SurveyDetailIn() {

    const { questions } = useQuestions()

    const navigate = useNavigate()
    const [activeKey, setActiveKey] = useState(localStorage.getItem('activeSurveyDetailIn') || '1');
    const [ageDetail, setAgeDetail] = useState([])
    const [genderDetail, setGenderDetail] = useState([])
    const [countQuestion, setCountQuestion] = useState([])
    const [surveyTile, setSurveyTitle] = useState([])
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        fetchSurveyData()
        fetchSurveyTitle()
    }, [])

    useEffect(() => {
        localStorage.setItem('activeSurveyDetailIn', activeKey);
    }, [activeKey]);

    const handleTabSelect = (key) => {
        setActiveKey(key);
    };

    const fetchSurveyData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/reporting/details-in/" + id}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setAgeDetail(response?.data?.Ages);
            setGenderDetail(response?.data?.Gender);
            setCountQuestion(response?.data?.countAllPeopleAnswerQuetionIn);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    const getCategoryQuestion = (questionId) => {
        return questions?.filter(q => q?.question_id === questionId);
    }

    const fetchSurveyTitle = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/get-survey/" + id}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setSurveyTitle(response?.data?.results);
            setIsLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false)
        }
    };

    const barchart = {
        series: [{
            data: genderDetail?.map(item => item?.count || 0)
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                fontFamily: 'Noto Sans Lao', // Set the desired font family
                fontWeight: 'normal', // Set the desired font weight
                fontStyle: 'normal', // Set the desired font style
                colors: 'red', // Specify your desired colors
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    endingShape: 'rounded'
                }
            },
            dataLabels: {
                enabled: true
            },
            chart: {
                fontFamily: 'Noto Sans Lao', // Set the desired font family
                toolbar: {
                    show: false,
                    fontFamily: 'Noto Sans Lao', // Set the desired font family
                }
            },
            xaxis: {
                categories: genderDetail?.map(item => item?.gender === "male" ? "ຊາຍ" : "ຍິງ" || ''),
            },
            colors: ['#3b5999', '#714b67'],
            toolbar: {
                show: false // Hide the download button
            }
        },
    }

    const dataSource = ageDetail?.map((e) => (
        {
            key: '1',
            gender: e?.employee_gender === "female" ? "ຍິງ" : "ຊາຍ",
            age: e?.age_group,
            count: <div className=' text-center'><Button className=' bg-[#3b5999]' type="primary" shape="circle">{e?.employee_count} </Button></div>,
        }
    ))

    const columns = [
        {
            title: 'ເພດ',
            dataIndex: 'gender',
            key: 'gender',
            align: 'center', // Set text alignment to center
        },
        {
            title: 'ຊວ່ງອາຍຸ',
            dataIndex: 'age',
            key: 'age',
            align: 'center', // Set text alignment to center
        },
        {
            title: 'ຈຳນວນ / ຄົນ',
            dataIndex: 'count',
            key: 'count',
            align: 'center', // Set text alignment to center
        },
    ];

    const column = [
        {
            title: 'ລຳດັບ',
            dataIndex: 'number',
            key: 'number',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'ຄຳຖາມ',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'ຈຳນວນຄົນຕອບ',
            dataIndex: 'lastname',
            key: 'lastname',
            render: (text) => <a>{text}</a>,
            align: 'center', // Set text alignment to center
        },
    ];


    const data = countQuestion?.map((item, index) => (
        {
            number: index + 1,
            name: getCategoryQuestion(item?.question_id)?.map((e) => e?.question_text),
            lastname: <div className=' text-center'><Button className=' bg-[#3b5999]' type="primary" shape="circle"> {item?.people_count} </Button></div>,
        }
    ))

    const items = [
        {
            key: '1',
            label: <span>ກ່ຽວກັບເພດ</span>,
            children:
                <div className='max-sm:pb-[300px]'>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 pt-3 pb-3 h-[70vh]">
                        <Card bordered={false} className=' max-sm:mt-3 shadow-md  shadow-[#3b5999]' >
                            <div className='  max-sm:flex-col flex  text-[18px] justify-between items-center '>
                                <div className=' max-sm:hidden'>ລາຍລະອຽດເພດ</div>
                            </div>
                            <Card
                                className=' border-t-1 border-r-0 border-b-0 border-l-4 border-[#3b5999] shadow-md shadow-gray-400 mt-3'
                            >
                                <div className=' flex '>
                                    <div className=' text-[20px] w-[50%]'>ຊາຍ</div>
                                    <div className=' text-[20px] w-[50%]'>{genderDetail?.[0]?.count ?? "0"} / ຄົນ</div>
                                </div>
                            </Card>
                            <Card
                                className='  border-t-1 border-r-0 border-b-0 border-l-4 border-[#3b5999] shadow-md  shadow-gray-400 mt-3'
                            >
                                <div className=' flex '>
                                    <div className=' text-[20px] w-[50%]'>ຍິງ</div>
                                    <div className=' text-[20px] w-[50%]'>{genderDetail?.[1]?.count ?? "0"} / ຄົນ</div>
                                </div>
                            </Card>
                            <div id="chart" className=' mt-3'>
                                <ReactApexChart options={barchart?.options} series={barchart?.series} type="bar" height={200} />
                            </div>
                        </Card>
                        <Card bordered={false} className=' max-sm:mt-3 shadow-md  shadow-gray-900' >
                            <div className='  max-sm:flex-col flex  text-[18px] justify-between items-center '>
                                <div className=' max-sm:hidden'>ລາຍລະອຽດອາຍຸ</div>
                            </div>
                            <Table className=' mt-3' dataSource={dataSource} columns={columns} />
                        </Card>
                    </div>
                </div>
        },
        {
            key: '2',
            label: <span>ກ່ຽວກັບຄຳຖາມ / ລາຍລະອຽດຄົນຕອບຄຳຖາມ</span>,
            children:
                <div>
                    <Table columns={column} dataSource={data} />
                </div>
        }
    ]

    return (
        <Appbar link={'/survey-list'} icons={<LeftOutlined />} title={<div>ລາຍລະອຽດເເບບຟອມ</div>}>
            <div className=' max-sm:pl-[20px] max-sm:pr-[20px] md:p-0 max-md:p-0 max-lg:p-0 lg:p-0  '>
                <Alert
                    message={<Breadcrumb
                        items={[
                            {
                                title: (
                                    <>
                                        <LeftOutlined />
                                        <span onClick={() => {
                                            navigate('/survey-list')
                                            fetchSurveyData()
                                        }} className=' ms-2 cursor-pointer'>ກັບຄືນລາຍການຟອມ</span>
                                    </>
                                ),
                            },
                            {
                                title: 'ລາຍລະອຽດເເບບຟອມ',
                            },
                        ]}
                    />}
                    type="info"
                    closable={false}
                    className=' max-sm:hidden mb-3    shadow-slate-900 '
                />

                <Card bordered={false} className=' max-sm:mt-3 shadow-md  shadow-gray-900'>
                    <div className='max-sm:flex-col flex  text-[18px] justify-between items-center'>
                        <div className='  font-bold'>ຫົວຂໍ້ : {surveyTile?.[0]?.title ?? "-"}</div>
                    </div>
                </Card>

                <Tabs
                    onChange={handleTabSelect}
                    activeKey={activeKey}
                    className='mt-3'
                    type="card"
                    defaultActiveKey="1" items={items}
                />

            </div>
        </Appbar>
    )
}

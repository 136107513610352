import React, { createContext, useContext, useEffect, useState } from 'react';
import { tokenData } from '../constants';
import { SERVER_URL } from '../api';
import axios from 'axios';

const BranchConext = createContext()

export default function BranchProvider({ children }) {

    const [branch, setBranch] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchBranchData()
    }, [])

    const fetchBranchData = async () => {

        setLoading(true)

        try {

            const response = await axios.get(`${SERVER_URL + "survey/getAll-Branches"}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });

            setBranch(response?.data?.results)
            setLoading(false)

        } catch (error) {

            console.error('Error fetching data:', error);
            setLoading(false)

        }
    };

    return (
        <BranchConext.Provider value={{ branch, setBranch, fetchBranchData, loading }} >
            {children}
        </BranchConext.Provider>
    )
}

export function useBranch() {
    return useContext(BranchConext)
}

import React, { createContext, useContext, useEffect, useState } from 'react';
import { tokenData } from '../constants';
import { SERVER_URL } from '../api';
import axios from 'axios';

const ChoiceContext = createContext()

export default function ChoiceProvider({ children }) {

    const [choices, setChoices] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchchoicesData()
    }, [])

    const fetchchoicesData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey-choice/choices"}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setChoices(response?.data?.results);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    // console.log("=============>choices", choices)

    return (
        <ChoiceContext.Provider value={{ choices, setChoices,fetchchoicesData, loading }} >
            {children}
        </ChoiceContext.Provider>
    )
}

export function useChoice() {
    return useContext(ChoiceContext)
}
import React, { useEffect, useState } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Form } from 'antd';
import axios from 'axios';
import { tokenData } from '../../api/auth';
import { useType } from '../../context/TypeContext';
import { SERVER_URL } from '../../api';
import { useCategory } from '../../context/CategoryContext';
import { Spinner } from 'react-bootstrap';

export default function CreateCategory({ isModalOpen, handleCancel, surveyLates, surveyId: id }) {

    const [form] = Form.useForm();
    const { fetchCategorysData } = useCategory()
    const [surveyId, setSurveyId] = useState()
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        if (surveyLates) {
            setSurveyId(surveyLates?.[0]?.valuses?.[0])
        }
    }, [surveyLates])

    const onCreateCategory = (values) => {

        setIsLoading(true)
        const apiUrl = `${SERVER_URL}survey/category/insert`;

        const requestData = {
            ...values,
            surveyId: surveyId || id
        };

        try {
            // Send a POST request using Axios
            axios.post(apiUrl, requestData, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                },
            })
                .then(response => {
                    // Handle the successful response
                    // console.log('Success:', response.data);
                    handleCancel()
                    fetchCategorysData()
                    setIsLoading(false)
                    form.resetFields();
                })
                .catch(error => {
                    // Handle errors
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        } catch (error) {
            console.log("Error: ", error)
            setIsLoading(false)
        }
    }

    return (
        <Modal
            title="ເພີ່ມໝວດໝູ່"
            open={isModalOpen}
            onOk={onCreateCategory}
            transitionName={false}
            centered
            okText={false}
            footer={[]}
            onCancel={handleCancel}>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    remember: true,
                    category_name: '',
                }}
                onFinish={onCreateCategory}
            >
                <div className=' pt-5 pb-5'>
                    <Form.Item name="category_name" label="ໝວດໝູ່" rules={[{ required: true, message: 'ກະລຸນາປ້ອນໝວດໝູ່!' }]} tooltip="ນີ້ເເມ່ນໝວດໝູ່">
                        <Input className='h-[40px]' />
                    </Form.Item>
                </div>
                <div className=' flex justify-end'>
                    <Button
                        onClick={() => handleCancel()}
                        className=' h-[40px]'
                        // type="primary"
                        icon={<CloseOutlined />}>
                        ຍົກເລິກ
                    </Button>
                    <Button
                        htmlType="submit"
                        className=' ms-2 bg-blue-600 h-[40px]'
                        type="primary"
                        icon={<CheckOutlined />}>
                        {isLoading ? <Spinner size='sm' /> : "ບັນທືກ"}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

import React, { useState, useEffect } from "react";
import { HomeOutlined, MenuOutlined, FileDoneOutlined, PieChartOutlined } from "@ant-design/icons";
import { Outlet } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { VERSION } from "../constants";
const { Header, Sider, Content, Footer } = Layout;

const MainLayout = () => {

  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(localStorage.getItem("selectedKey") || "/management-list");
  const [collapsed, setCollapsed] = useState(false);
  const { token: { colorBgContainer } } = theme.useToken();

  useEffect(() => {
    localStorage.setItem("selectedKey", selectedKey);
  }, [selectedKey]);

  const menu = [
    {
      key: "/home",
      icon: <HomeOutlined style={{ fontSize: 20 }} />,
      label: "ໜ້າຫຼັກ",
    },
    {
      key: "/dashboard",
      icon: <PieChartOutlined style={{ fontSize: 20 }} />,
      label: "ລາຍງານ",
    },
    {
      key: "/survey-list",
      icon: <FileDoneOutlined style={{ fontSize: 20 }} />,
      label: "ຈັດການເເບບຟອມ",
    },
    {
      key: "/question",
      icon: <FileDoneOutlined style={{ fontSize: 20 }} />,
      label: "ຕອບຄຳຖາມ",
    },
  ];

  return (
    <Layout>
      <Sider
        ແ trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ background: "#111827" }}
      >
        <div className="logo">
          <h2 className="text-white fs-5 text-center py-3 mb-0">
            <span className="sm-logo">P</span>
            {/* <span className="lg-logo">NOTIFICATION</span> */}
          </h2>
        </div>

        <Menu
          theme="dark"
          mode="inline"
          style={{ background: "#111827" }}
          onClick={({ key }) => {
            if (key == "/home") {
              window.location.href = "http://next.phongsavanhgroup.com";
            } else {
              navigate(key);
              setSelectedKey(key);
            }
          }}
          defaultSelectedKeys={[selectedKey]}
          items={menu}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex justify-content-between ps-1 pe-5 "
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuOutlined : MenuOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <span className=" font-medium">VERSION {VERSION}</span>
        </Header>
        <Content
          style={{
            margin: "16px 16px",
            padding: 20,
            minHeight: 280,
            overflowY: "auto",
            background: "#64748b",
          }}
        >
          <Outlet />
        </Content>
        {/* <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Phongsavanh Group ©2023
        </Footer> */}
      </Layout>
    </Layout>
  );
};
export default MainLayout;

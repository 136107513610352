import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import axios from 'axios';

const TypeContext = createContext()

export default function TypeProvider({ children }) {

    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchTypeData()
    }, [])

    const fetchTypeData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey-types/types"}`, {
                headers: {
                    // Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setTypes(response?.data?.results);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    // console.log("=========types", types)

    return (
        <TypeContext.Provider value={{ types, setTypes, fetchTypeData, loading }} >
            {children}
        </TypeContext.Provider>
    )
}

export function useType() {
    return useContext(TypeContext)
}
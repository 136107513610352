import React from 'react'
import { Button, Result, Watermark } from 'antd';

export default function QuestionResult() {
    return (

        <div className=' h-screen flex justify-center items-center bg-[#e2e8f0]'>
            <Watermark
                // content={['Phongsavanh']}
                inherit={false}
            // height={30}
            // width={130}
            // image="https://mdn.alipayobjects.com/huamei_7uahnr/afts/img/A*lkAoRbywo0oAAAAAAAAAAAAADrJ8AQ/original"
            >
                <Result
                    className="shadow-md bg-white lg:p-40"
                    status="success"
                    title="ທ່ານໄດ້ສົ່ງຄຳຕອບສຳເລັດຮຽບຮອ້ຍເເລ້ວ"
                    // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                    extra={[
                        // <Button type="primary" key="console" className=' bg-blue-900'>
                        //     ກັບຄືນ
                        // </Button>,
                        // <Button key="buy">Buy Again</Button>,
                    ]}
                />
            </Watermark>
        </div>
    )
}

import React, { createContext, useContext, useEffect, useState } from 'react';
import { tokenData } from '../constants';
import { SERVER_URL } from '../api';
import axios from 'axios';

const CategoryConext = createContext()

export default function CategoryProvider({ children }) {

    const [categorys, setCategorys] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchCategorysData()
    }, [])

    const fetchCategorysData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/category/categories"}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setCategorys(response?.data?.results)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    return (
        <CategoryConext.Provider value={{ categorys, setCategorys,fetchCategorysData, loading }} >
            {children}
        </CategoryConext.Provider>
    )
}

export function useCategory() {
    return useContext(CategoryConext)
}

import Swal from 'sweetalert2'
import { renderToString } from 'react-dom/server';
import { Button } from 'antd';

export const VERSION = "1.0.0"

const confirmButtonContent = renderToString(
    <Button type="primary">
        ຕົກລົງ
    </Button>
);
const cancelButtonContent = renderToString(
    <Button type="primary">
        ຍົກເລິກ
    </Button>
);

export const onConfirmButton = (title, onConfirm) => {
    Swal.fire({
        title: title,
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmButtonContent,
        cancelButtonText: cancelButtonContent
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm()
        }
    });
}


const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
};

const tokenString = getCookie('user');

// Decode the URL-encoded string
const decodedTokenString = decodeURIComponent(tokenString);

export const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;
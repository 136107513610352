import React, { useState, useEffect } from 'react'
import { DeleteOutlined, PlusOutlined, EditOutlined, CloudFilled } from '@ant-design/icons';
import { Avatar, Radio, Button, List, Card, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useChoice } from '../../context/ChoiceContext';
import { onConfirmButton } from '../../constants';
import { successSwal } from '../../constants/sweetalert';
import { SERVER_URL } from '../../api';
import axios from 'axios';
import { tokenData } from '../../api/auth';
import { useQuestions } from '../../context/QuestionContext';
import { Spinner } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import UpdateQuestion from './UpdateQuestion';
import { useType } from '../../context/TypeContext';
import { useSurvey } from '../../context/SurveyContext';
import { useCategory } from '../../context/CategoryContext';

export default function QuestionList({
    setCreateCategory,
    setCreateQuestion,
    surveyId,
    answers,
    contextHolder,
    surveyLates,
    setAnswers,
    selectedTypeId,
    setSurveyId }) {

    const { categorys } = useCategory()
    const { choices } = useChoice()
    const { survey, surveyOutAcive } = useSurvey()
    const { types } = useType()
    const [questionImage, setQuestionImage] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { questions, setQuestions, loading, fetchquestionsData } = useQuestions()
    const [Delete, setDelete] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Set your desired page size
    const [updateQuestion, setUpdateQuestion] = useState(false)
    const [selectedUpdateQuestion, setSelectUpdateQuestion] = useState()

    const [trueChoice, setTrueChoice] = useState()
    const [trueQuestion, setTrueQuestion] = useState()

    const questionData = questions?.filter((id) => id?.survey_id === surveyId)
    const typeStatusData = survey?.filter((id) => id?.surveys_id === surveyId)
    const typeStatusDataOut = surveyOutAcive?.filter((id) => id?.surveys_id === surveyId)
    const type_statue = typeStatusData?.[0]?.type_status
    const type_statueOut = typeStatusDataOut?.[0]?.type_status

    // useEffect(() => {
    //     fetchImageQuestion()
    // }, [])


    const getChoicesForQuestion = (questionId) => {
        return choices.filter(choice => choice?.question_id === questionId);
    };

    const getCategoryQuestion = (questionId) => {
        return categorys?.filter(category => category?.category_id === questionId);
    }

    // const getCategoryQuestion = (questionId) => {
    //     // Filter categories with the provided category ID and skip categories with the name "educations"
    //     const filteredCategories = categorys.filter(category => category?.category_id === questionId);

    //     // Return only the first category in the filtered list, if any
    //     return filteredCategories.length > 0 ? [filteredCategories[0]] : [];
    // }


    // Calculate the start and end index based on the current page and page size
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Slice the questions data to show only the questions for the current page
    const paginatedQuestionData = questionData?.slice(startIndex, endIndex);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
    };

    const handleShowDelete = (id, question_text) => {
        setDelete({ question_text, id });
        onConfirmButton('ທ່ານຕອ້ງການລຶບເເທ້ ຫຼື ບໍ່ ?', () => onDeleteQuestion(id))
    };

    const onDeleteQuestion = (id) => {

        const apiUrl = `${SERVER_URL}survey/question/delete-survey-question/${id}`

        try {
            axios.delete(apiUrl)
                .then(response => {
                    fetchquestionsData()
                    successSwal('ລຶບສຳເລັດ')
                })
                .catch(error => {
                    console.error('Error deleting survey', error);
                    successSwal('ມີຂໍ້ຜິດພາດ ລຶບບໍ່ສຳເລັດ')
                });
        } catch (error) {
            console.log("Error: ", error)
        }

    }

    const handleRadioChange = async (e, questionId) => {

        const selectedChoiceId = e.target.value;

        // Find the choice based on the selectedChoiceId
        const selectedChoice = getChoicesForQuestion(questionId)?.find(choice => choice.choice_id === selectedChoiceId);

        // Update both states
        setTrueChoice(selectedChoiceId);
        setTrueQuestion(selectedChoice?.question_id);
        // Insert the true choice immediately
        await onCreateTrueChoice();

    };

    const onCreateTrueChoice = async () => {

        const apiUrl = `${SERVER_URL}survey/true-choice/insert`;

        try {
            const requestBody = {
                choice_id: trueChoice,
                question_id: trueQuestion,
                survey_id: surveyId
            };

            const response = await axios.post(apiUrl, requestBody, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                },
            });

            successSwal('ຕັ້ງເປັນຄຳຕອບ ສຳເລັດ')

        } catch (error) {
            console.error('Error making POST request:', error);
        }
    };

    // console.log("==============>questionData", questionData)
    // console.log("==============>typeStatusData", typeStatusData)
    // console.log("==============>type_statue", type_statue)
    // console.log("==============>survey", survey)

    let foundDuplicate = false; // เพิ่มตัวแปรเพื่อตรวจสอบว่าพบ ID ที่ซ้ำกันหรือไม่
    return (
        <div>
            {contextHolder}
            <Card
                bordered={false}
            >
                <div className=' max-sm:flex-col flex  items-center'>
                    <Button
                        onClick={() => setCreateQuestion(true)}
                        className=' bg-blue-500 h-[40px]'
                        type="primary"
                        icon={<PlusOutlined />}>
                        ເພີ່ມຄຳຖາມ
                    </Button>
                    <div className=' max-sm:mt-3 lg:ms-2 text-[14px]'>
                        <Pagination
                            current={currentPage}
                            total={questionData?.length}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                        />
                    </div>
                    <div className='max-sm:mt-3 md:ms-5 lg:ms-5 text-[16px]'>ລວມທັງໜົດ {questionData?.length} ຂໍ້</div>
                </div>
            </Card>

            <Card className='mt-3' bordered={false} >
                <List
                    itemLayout="horizontal"
                    dataSource={paginatedQuestionData}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                // avatar={
                                //     <div><img src={'https://louisvillezoo.org/wp-content/uploads/2023/02/2023-02-22-Earth-Month-1.jpg'} /> fnvfudi</div>
                                // }
                                title={<div>

                                    {/* <div>
                                        {getCategoryQuestion(item?.category_id).map(category =>
                                            <div key={category?.category_id} className='font-bold'>
                                                {category?.category_name} 
                                            </div>
                                        )
                                        }
                                    </div> */}

                                    <div className=' flex justify-between'>

                                        <div className=' text-[18px] w-[80%] font-medium'>{index + 1 + pageSize * (currentPage - 1)}.&ensp;{item?.question_text}</div>
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    setUpdateQuestion(true)
                                                    setSelectUpdateQuestion(item)
                                                }}
                                                className=' bg-green-900'
                                                type="primary"
                                                icon={<EditOutlined />}>
                                                ເເກ້ໄຂ
                                            </Button>
                                            <Button
                                                onClick={() => handleShowDelete(item?.question_id)}
                                                className=' bg-red-600 ms-2'
                                                type="primary"
                                                icon={<DeleteOutlined />}>
                                                ລຶບ
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={` ${!item?.images ? " hidden" : "mt-3"} `}><img width={'100%'} src={item?.images} style={{ height: 250, objectFit: "cover" }} /></div>
                                </div>}
                                description={<div className=' mt-3'>
                                    {
                                        <Radio.Group disabled={type_statue === 1 || type_statueOut === 1 ? false : true}>
                                            {getChoicesForQuestion(item?.question_id).map(choice => (
                                                <div key={choice.id}>
                                                    <Radio className='p-2' value={choice?.choice_id} onChange={(e) => handleRadioChange(e, item?.question_id)} >  {choice.choice_text}</Radio>
                                                </div>
                                            ))}
                                        </Radio.Group>
                                    }
                                </div>}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <UpdateQuestion
                isModalOpen={updateQuestion}
                handleCancel={() => setUpdateQuestion(false)}
                surveyId={surveyId}
                setSurveyId={setSurveyId}
                surveyLates={surveyLates}
                // messageApi={messageApi}
                setCreateCategory={setCreateCategory}
                selectedUpdateQuestion={selectedUpdateQuestion}
            />
        </div>
    )
}

import React, { useState } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Form, Select } from 'antd';
import axios from 'axios';
import { tokenData } from '../../constants';
import { useType } from '../../context/TypeContext';
import { SERVER_URL } from '../../api';
import { Spinner } from 'react-bootstrap';

export default function CreateType({ isModalOpen, handleCancel }) {

    const { types, fetchTypeData } = useType()
    const [typeStatus, setTypeStatus] = useState("0")
    const [isLoading, setIsLoading] = useState(false)

    const onCreateType = (values) => {

        setIsLoading(true)

        const apiUrl = `${SERVER_URL}survey-types/insert`;

        const data = {
            ...values,
            type_status: typeStatus
        }

        try {
            // Send a POST request using Axios
            axios.post(apiUrl, data, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            })
                .then(response => {
                    // Handle the successful response
                    // console.log('Success:', response.data);
                    handleCancel()
                    fetchTypeData()
                    setIsLoading(false)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        } catch (error) {
            console.log("Error: ", error)
            setIsLoading(false)
        }
    }

    return (
        <Modal
            title="ເພີ່ມປະເພດຟອມ"
            open={isModalOpen}
            onOk={onCreateType}
            transitionName={false}
            centered
            okText={false}
            footer={[]}
            onCancel={handleCancel}>
            <Form
                layout="vertical"
                initialValues={{
                    remember: true,
                    type_name: '',
                    type_status: typeStatus
                }}
                onFinish={onCreateType}>

                <div className=' pt-3 pb-5'>
                    <Form.Item name="type_name" label="ຫົວຂໍ້" rules={[{ required: true, message: 'ກະລຸນາປ້ອນປະເພດຟອມ!' }]} tooltip="ນີ້ເເມ່ນປະເພດຟອມ">
                        <Input className='h-[40px]' />
                    </Form.Item>
                    <Form.Item name="type_status" label="ປະເພດຄຳຕອບ" rules={[{ required: true, message: 'ກະລຸນາເລືອກປະເພດຄຳຕອບ!' }]} tooltip="ນີ້ເເມ່ນປະເພດຟອມ">
                        <Select
                            defaultValue="0"
                            onChange={(value) => setTypeStatus(value)}
                            className=' h-[40px]'
                            options={[
                                {
                                    value: '1',
                                    label: 'ມີຄຳຕອບ',
                                },
                                {
                                    value: '0',
                                    label: 'ທົ່ວໄປ',
                                }
                            ]}
                        />
                    </Form.Item>
                </div>

                <div className=' flex justify-end'>
                    <Button
                        onClick={() => handleCancel()}
                        className=' h-[40px]'
                        // type="primary"
                        icon={<CloseOutlined />}>
                        ຍົກເລິກ
                    </Button>
                    <Button
                        htmlType="submit"
                        className=' bg-blue-600 h-[40px] ms-2'
                        type="primary"
                        icon={<CheckOutlined />}>
                        {isLoading ? <Spinner size='sm' /> : "ບັນທືກ"}
                    </Button>
                </div>

            </Form>
        </Modal>
    )
}

import React, { useState, useEffect } from 'react'
import { LeftOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons';
import { Result, Radio, List, Button, Card, Tag, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { tokenData } from '../../constants';
import moment from 'moment';
import { SERVER_URL } from '../../api';
import EditForm from './EditForm';


export default function FormList({
    selectedItems,
    setSelectedItems,
    selectedItemsDepartment,
    setSelectedItemsDepartment,
    setCreateForm,
    fetchSurveyId,
    surveyLates,
    surveyData }) {


    const [editSurvey, setEditSurvey] = useState(false)
    const [selectedSurvey, setSelectedSurvey] = useState()

    useEffect(() => {
        fetchSurveyId()
    }, [])

    useEffect(() => {
        if (surveyLates) {
            fetchSurveyId()
        }
    }, [surveyLates])

    const columns = [
        {
            title: 'ຫົວເລື່ອງ',
            dataIndex: 'title',
            key: 'title',
            //   render: (text) => <a>{text}</a>,
            ellipsis: true,
        },
        {
            title: 'ລາຍລະອຽດ',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
        },
        {
            title: 'ວັນທີເລີ່ມ',
            dataIndex: 'startDate',
            key: 'startDate',
            ellipsis: true,
        },
        {
            title: 'ຫາວັນທີ',
            dataIndex: 'EndDate',
            key: 'EndDate',
            ellipsis: true,
        },
        {
            title: 'ຮູບເເບບ',
            dataIndex: 'Type',
            key: 'Type',
            ellipsis: true,
        },
    ];

    const data = surveyData?.map((item) => (
        {
            title: item?.title,
            description: item?.descriptions,
            startDate: moment(item?.start_date)?.format("DD-MM-YYYY"),
            EndDate: moment(item?.end_date)?.format("DD-MM-YYYY"),
            Type: <Tag className=' p-2 text-[14px]' color={item?.surveys_status === "in" ? "blue" : "#f50"}>{item?.surveys_status === "in" ? "ເເບບຟອມພາຍໃນ" : "ເເບບຟອມພາຍນອກ"}</Tag>,
        }
    ))

    return (
        <div>
            <Card bordered={false}>

                <div className=' flex items-center'>
                    <Button
                        // disabled={surveyLates && surveyLates.length >= 1}
                        onClick={() => setCreateForm(true)}
                        className={` bg-blue-500 h-[40px] ${surveyLates?.length > 0 ? 'hidden' : ""} `}
                        type="primary"
                        icon={<PlusOutlined />}>
                        ສ້າງເເບບຟອມ
                    </Button>
                    <Button
                        // disabled={surveyLates && surveyLates.length >= 1}
                        onClick={() => {
                            setEditSurvey(true)
                            setSelectedSurvey(surveyData)
                        }}
                        className={` bg-blue-500 h-[40px] ${surveyLates?.length <= 0 ? 'hidden' : ""} `}
                        type="primary"
                        icon={<FormOutlined />}>
                        ເເກ້ໄຂເເບບຟອມ
                    </Button>
                </div>

                {/* <div className=' mt-4'>
                    <List
                        // className={`${index === 0 ? '' : 'mt-3'}`}
                        size="small"
                        // bordered
                        dataSource={surveyData} // You need to provide an array for the dataSource prop
                        renderItem={(item, index) => (
                            <>
                                <List.Item>
                                    <div className='flex w-full'>
                                        <div className='w-[10%]'>ຫົວເລື່ອງ</div>
                                        <div className='w-[90%]'>{item?.title}</div>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className='flex w-full'>
                                        <div className='w-[10%]'>ລາຍລະອຽດ</div>
                                        <div className='w-[90%]'>{item?.descriptions}</div>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className='flex w-full'>
                                        <div className='w-[10%]'>ວັນທີເລີ່ມ</div>
                                        <div className='w-[90%]'>{moment(item?.start_date)?.format("DD-MM-YYYY")}</div>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className='flex w-full'>
                                        <div className='w-[10%]'>ຫາວັນທີ</div>
                                        <div className='w-[90%]'>{moment(item?.end_date)?.format("DD-MM-YYYY")}</div>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className='flex w-full'>
                                        <div className='w-[10%]'>ຮູບເເບບ</div>
                                        <div className='w-[90%]'>
                                            {item?.surveys_status === "in" ? "ເເບບຟອມພາຍໃນ" : "ເເບບຟອມພາຍນອກ"}
                                        </div>
                                    </div>
                                </List.Item>
                            </>
                        )}
                    />
                </div> */}
                {/* <Skeleton loading={loading} active avatar> */}
                <Table scroll={{ x: true }} className=' mt-3' columns={columns} dataSource={data} />
                {/* </Skeleton> */}

                <EditForm
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    selectedItemsDepartment={selectedItemsDepartment}
                    setSelectedItemsDepartment={setSelectedItemsDepartment}
                    isModalOpen={editSurvey}
                    handleCancel={() => setEditSurvey(false)}
                    selectedSurvey={selectedSurvey}
                    fetchSurveyId={fetchSurveyId}
                />

            </Card>
        </div>
    )
}

import React from "react";
import { Modal } from "antd";
import { Spinner } from "react-bootstrap";

export default function PopupConfirm({ show, handleClose, title, description, icon, loading, onSave }) {
  return (
    <div>
      <Modal
        // title="Basic Modal"
        open={show}
        onOk={onSave}
        centered
        transitionName={false}
        closable={false}
        okText={loading ? <Spinner size="sm" /> : "ຕົກລົງ"}
        cancelText={"ຍົກເລີກ"}
        onCancel={handleClose}
      >
        <div className=" flex justify-center flex-col items-center leading-3 pt-[80px] pb-[80px] text-[18px] ">
          <span>{icon}</span>
          <span style={{ fontSize: 20 }}>{title}</span>
          <span>{description}</span>
        </div>
      </Modal>
    </div>
  );
}

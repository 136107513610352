import React, { useState, useEffect } from 'react'
import { CheckCircleOutlined, FieldTimeOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Card, Tabs, Skeleton, Result, Badge, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useSurvey } from '../../context/SurveyContext';
import moment from 'moment';
import Appbar from '../../components/Appbar';
import { useSurveyUser } from '../../context/SurveyUserContext';
import Swal from 'sweetalert2';
import { warring } from '../../constants/sweetalert';
import { Spinner } from 'react-bootstrap';

const { Meta } = Card;

export default function Question() {

    const navigate = useNavigate()
    const [activeKey, setActiveKey] = useState(localStorage.getItem('activeSurveyStatusAnswer') || '1');
    // const { survey, surveyInActive, surveyOutAcive, loading } = useSurvey()
    const { surveyUser, surveyUserOut, fetchSurveyUserData, fetchSurveyUserOutData, loading } = useSurveyUser()

    useEffect(() => {
        localStorage.setItem('activeSurveyStatusAnswer', activeKey);
    }, [activeKey]);

    const onAnswer = (id) => {
        navigate("/private/forms/" + id);
    }

    const surveyUserIn = surveyUser?.filter(item => item?.surveys_status === "in")

    // console.log("==========surveyUserIn", surveyUserIn)
    // console.log("==========surveyUserOut", surveyUserOut)

    // console.log("surveyUser=============surveyUser", surveyUser)

    const calculateDaysDifference = (advertEndDate) => {
        const endDate = moment(advertEndDate);
        const now = moment();

        // Calculate the difference in days
        const days = endDate.diff(now, "days");

        // Calculate the difference in hours (excluding full days)
        const hours = endDate.subtract(days, "days").diff(now, "hours");

        // Calculate the difference in minutes (excluding full days and full hours)
        const minutes = endDate.subtract(hours, "hours").diff(now, "minutes");

        return { days, hours, minutes };
    };

    const convertStillTime = (pro) => {
        const timeDiffStart = calculateDaysDifference(pro?.start_date);

        if (timeDiffStart.minutes >= 0) {
            return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-red-600 text-[20px]' />} > <div className=' text-red-600 font-bold' > ຍັງບໍ່ຮອດເວລາ </div></Tag>;
        }

        const timeDiff = calculateDaysDifference(pro?.end_date);

        if (timeDiff.days > 0) {
            return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-green-800  text-[20px]' />} > <div className=' text-green-800 font-bold'> ເຫຼືອເວລາ {`${timeDiff.days} ມື້ ${timeDiff.hours} ຊົ່ວໂມງ ${timeDiff.minutes} ນາທີ`} </div></Tag>
        }
        if (timeDiff.hours > 0) {
            return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-green-800  text-[20px]' />} ><div className=' text-green-800 font-bold'> ເຫຼືອເວລາ {`${timeDiff.hours} ຊົ່ວໂມງ ${timeDiff.minutes} ນາທີ`}</div> </Tag>;
        }
        if (timeDiff.minutes >= 0) {
            return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-green-800  text-[20px]' />} > <div className=' text-green-800 font-bold'> ເຫຼືອເວລາ {`${timeDiff.minutes} ນາທີ`} </div></Tag>;
        }
        return <Tag className=' w-full flex items-center h-10  text-[14px] mt-2' icon={<FieldTimeOutlined className=' text-red-600 text-[20px]' />} >  <div className=' text-red-600 font-bold'>ໝົດອາຍຸ</div> </Tag>;
    }

    const checkButtonDateAnswer = (pro) => {
        const timeDiffStart = calculateDaysDifference(pro?.start_date);

        if (timeDiffStart.minutes >= 0) {
            return warring('ຂໍອະໄພຍັງບໍ່ຮອດເວລາຕອບຄຳຖາມເທື່ອ !');
        }

        const timeDiff = calculateDaysDifference(pro?.end_date);

        if (timeDiff.days > 0) {
            return onAnswer(pro?.surveys_id)
        }

        if (timeDiff.hours > 0) {
            return onAnswer(pro?.surveys_id)
        }

        if (timeDiff.minutes >= 0) {
            return onAnswer(pro?.surveys_id)
        }

        return warring('ຂໍອະໄພໝົດເວລາໃນການຕອບຄຳຖາມເເລ້ວ !');
    }


    const checkDisabledButton = (pro) => {
        const timeDiffStart = calculateDaysDifference(pro?.start_date);

        if (timeDiffStart.minutes >= 0) {
            return "opacity-50";
        }

        const timeDiff = calculateDaysDifference(pro?.end_date);

        if (timeDiff.days > 0) {
            return ''
        }

        if (timeDiff.hours > 0) {
            return ''
        }

        if (timeDiff.minutes >= 0) {
            return ''
        }

        return "opacity-50";
    }

    const items = [
        {
            key: '1',
            label: <span>ເເບບຟອມພາຍໃນ ({surveyUserIn?.length})</span>,
            children:
                <div className='max-sm:mt-2 max-md:mt-2 md:mt-2 max-lg:mt-2 lg:mt-2'>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4  pb-3">

                        {Array?.from({ length: 3 }, (_, index) => (
                            <Skeleton
                                className='w-full'
                                key={index}
                                active
                                loading={loading}
                                paragraph={{ rows: 4 }}
                            />
                        ))}

                        {surveyUserIn?.map((e) => (
                            <Badge.Ribbon className={` ${e?.count_answer === 0 ? "hidden" : ""} bg-green-600 ${e?.count_answer === 0 ? "" : "opacity-90"}`} text={
                                <div className='flex items-center p-[3px]'>
                                    <div> <CheckCircleOutlined className='mr-2 text-[20px]' /></div>
                                    <div>ທ່ານຕອບຄຳຖາມສຳເລັດເເລ້ວ</div>
                                </div>
                            } color={""} >
                                <Card
                                    className={`  ${e?.count_answer === 0 ? "" : "opacity-90"} shadow-sm hover:shadow-lg  shadow-slate-900 `}
                                    actions={[

                                        e?.count_answer === 0 ?
                                            <Button
                                                // disabled={checkButtonDateAnswer(e)}
                                                // onClick={() => onAnswer(e?.surveys_id)}
                                                onClick={() => checkButtonDateAnswer(e)}
                                                type="primary"
                                                className={` ${checkDisabledButton(e)}  ${e?.count_answer === 0 ? "" : "hidden"} ${e?.count_answer === 0 ? "" : "opacity-90"} bg-[#3b5999] text-white hover:bg-blue-600  border-gray-200 hover:border-none hover:text-white hover:ring-sky-100`}
                                            >
                                                ຕອບຄຳຖາມ
                                            </Button> :
                                            <Button
                                                onClick={() => Swal.fire({
                                                    // icon: "question",
                                                    icon: "success",
                                                    title: "ທ່ານໄດ້ຕອບຄຳຖາມສຳເລັດເເລ້ວ...",
                                                    // text: "That thing is still around?",
                                                })}
                                                type="primary"
                                                className={` ${e?.count_answer === 0 ? " hidden" : ""}   bg-green-600 hover:bg-blue-600  text-white border-gray-200 hover:border-none hover:text-white hover:ring-sky-100`}
                                            >
                                                ເບີ່ງຄືນຄຳຕອບ
                                            </Button>
                                    ]}
                                >
                                    <Meta
                                        className='mt-4'
                                        // avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                        title={
                                            <div className=' flex justify-between'>
                                                <div>
                                                    {_.truncate(e?.title, {
                                                        length: 40,
                                                    })}
                                                </div>
                                            </div>
                                        }
                                        description={
                                            <div className=' text-[16px]'>
                                                {/* <div>{e?.surveys_status === "out" ? "ເເບບຟອມພາຍນອກ" : "ເເບບຟອມພາຍໃນ"}</div> */}
                                                {/* <div>
                                                    <span>ເລີ່ມວັນທີ {moment(e?.start_date).format("YYYY-MM-DD")}</span>
                                                    <span className=' ms-2'>ຫາວັນທີ {moment(e?.end_date).format("YYYY-MM-DD")}</span>
                                                </div> */}
                                                <div>ເວລາກຳນົດ </div>
                                                <div className=' flex w-full'>
                                                    {convertStillTime(e)}
                                                </div>
                                            </div>
                                        }
                                    />
                                </Card>
                            </Badge.Ribbon>
                        ))
                        }
                    </div >
                    {
                        !loading && surveyUserIn?.length <= 0 && (
                            <Result
                                status="warning"
                                title="ຍັງບໍ່ມີຂໍ້ມູນເທື່ອ !"
                            />
                            // <Empty />
                        )
                    }
                </div >,
        },
        // {
        //     key: '2',
        //     label: <span>ເເບບຟອມພາຍນອກ ({surveyUserOut?.length})</span>,
        //     children:
        //         <div className='max-sm:mt-2 max-md:mt-2 md:mt-2 max-lg:mt-2 lg:mt-2'>
        //             <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 pt-3 pb-3">

        //                 {Array?.from({ length: 3 }, (_, index) => (
        //                     <Skeleton
        //                         className='w-full'
        //                         key={index}
        //                         active
        //                         loading={loading}
        //                         paragraph={{ rows: 4 }}
        //                     />
        //                 ))}

        //                 {surveyUserOut?.map((e) => (
        //                     <Badge.Ribbon className={` ${e?.count_answer === 0 ? "hidden" : ""}`} text={
        //                         <div className='flex items-center p-[3px]'>
        //                             <div> <CheckCircleOutlined className='mr-2 text-[20px]' /></div>
        //                             <div>ຕອບຄຳຖາມສຳເລັດ</div>
        //                         </div>
        //                     } color={"green"}>
        //                         <Card
        //                             className='shadow-sm hover:shadow-lg shadow-slate-900'
        //                             actions={[
        //                                 <Button
        //                                     onClick={() => onAnswer(e?.surveys_id)}
        //                                     type="primary"
        //                                     className='hover:bg-blue-600 text-gray-900 border-gray-200 hover:border-none hover:text-white hover:ring-sky-100'
        //                                 >
        //                                     ຕອບຄຳຖາມ
        //                                 </Button>

        //                             ]}
        //                         >
        //                             <Meta
        //                                 className='mt-4'
        //                                 // avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
        //                                 title={
        //                                     <div className=' flex justify-between'>
        //                                         <div>
        //                                             {_.truncate(e?.title, {
        //                                                 length: 40,
        //                                             })}
        //                                         </div>
        //                                     </div>
        //                                 }
        //                                 description={
        //                                     <div className=' text-[16px]'>
        //                                         <div>{e?.surveys_status === "out" ? "ເເບບຟອມພາຍນອກ" : "ເເບບຟອມພາຍໃນ"}</div>
        //                                         <div>
        //                                             <span>ເລີ່ມວັນທີ {moment(e?.start_date).format("YYYY-MM-DD")}</span>
        //                                             <span className=' ms-2'>ຫາວັນທີ {moment(e?.end_date).format("YYYY-MM-DD")}</span>
        //                                         </div>
        //                                         {/* <div className=' mt-3'>
        //                         <Tag
        //                             className=' h-7 flex justify-center items-center w-25'
        //                             color={e?.is_active === 1 ? "green" : "red"}>
        //                             {e?.is_active === 1 ? "ໃຊ້ງານ" : "ບໍ່ໄດ້ໃຊ້ງານ"}
        //                         </Tag>
        //                     </div> */}
        //                                     </div>
        //                                 }
        //                             />
        //                         </Card>
        //                     </Badge.Ribbon>
        //                 ))}
        //             </div>
        //             {
        //                 !loading && surveyUserOut?.length <= 0 && (
        //                     <Result
        //                         status="warning"
        //                         title="ຍັງບໍ່ມີຂໍ້ມູນເທື່ອ !"
        //                     />
        //                     // <Empty />
        //                 )
        //             }
        //         </div>,
        // }
    ];

    return (
        <Appbar title={'ຕອບຄຳຖາມ'}>
            <div className=' max-sm:pl-[20px] max-sm:pr-[20px] md:p-0 max-md:p-0 max-lg:p-0 lg:p-0 '>

                <Card bordered={false} className=' max-sm:mt-3  shadow-sm  shadow-gray-900' >
                    <div className='max-sm:flex-col flex  text-[18px] justify-between items-center '>
                        <div className=''>ເເບບຟອມສຳລັບຕອບທັງໝົດ</div>
                        {/* <div className=''>ເເບບຟອມທັງໝົດ ( {survey?.length} ) ລາຍການ</div> */}
                        <div className=' max-sm:mt-3 max-sm:w-full max-sm:flex max-sm:justify-center'>
                            <Button
                                onClick={fetchSurveyUserData}
                                type="" className=' max-sm:w-[50%] bg-blue-600 text-white h-[40px]' icon={loading ? <Spinner size='sm' /> : <SyncOutlined />}>
                                ຮີເຟັສ
                            </Button>
                        </div>
                    </div>
                </Card>

                <Tabs
                    className='mt-3'
                    type="card"
                    defaultActiveKey="1" items={items}
                />

            </div>
        </Appbar>
    )
}

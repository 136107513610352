import './App.css';
import { ConfigProvider } from 'antd';
import Router from './route/Router';
import 'bootstrap/dist/css/bootstrap.min.css';
import SurveyProvider from './context/SurveyContext';
import QuestionProvider from './context/QuestionContext';
import ChoiceProvider from './context/ChoiceContext';
import ParticipansProvider from './context/ParticipanContext';
import CategoryProvider from './context/CategoryContext';
import TypeProvider from './context/TypeContext';
import BranchProvider from './context/BranchContext';
import SurveyUserProvider from './context/SurveyUserContext';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Noto Sans Lao"
        },
        components: {
          Menu: {
            activeBarBorderWidth: 10,
            activeBarHeight: 0,
            itemSelectedBg: "#e6f4ff",
            itemHoverColor: "#FFFFFF",
            itemActiveBg: "red",
            // colorPrimary:"green",
            // colorPrimaryBorder:"green"
          },
          Radio: {
            radioSize: 25
          },
          Breadcrumb: {
            lineHeight: 3,
          },
          Alert: {
            colorInfoBg: "#FFFFFF",
            colorInfoBorder: "#FFFFFF",
          },
        },
      }}>
      <SurveyUserProvider>
        <BranchProvider>
          <TypeProvider>
            <CategoryProvider>
              <ParticipansProvider>
                <ChoiceProvider>
                  <QuestionProvider>
                    <SurveyProvider>
                      <Router />
                    </SurveyProvider>
                  </QuestionProvider>
                </ChoiceProvider>
              </ParticipansProvider>
            </CategoryProvider>
          </TypeProvider>
        </BranchProvider>
      </SurveyUserProvider>
    </ConfigProvider>
  );
}

export default App;

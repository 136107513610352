import React, { useState, useEffect } from 'react';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Radio, message, Progress, Divider, Form, Input, Select, DatePicker } from 'antd';
import Appbar from '../../components/Appbar';
import { tokenData } from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuestions } from '../../context/QuestionContext';
import { useChoice } from '../../context/ChoiceContext';
import { SERVER_URL } from '../../api';
import Swal from 'sweetalert2';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Spinner } from 'react-bootstrap';
import { errorSwal, successSwal, warring } from '../../constants/sweetalert';
dayjs.extend(utc);
export default function QuestionOut() {

    const { id } = useParams()
    const navigate = useNavigate()
    const { choices } = useChoice()
    const { questions } = useQuestions()
    const [surveyHead, setSurveyHead] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isForm, setIsForm] = useState(true)

    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [date, setDate] = useState(null)
    const [gender, setGender] = useState('male')
    const [otherDetail, setOtherDetail] = useState()

    const questionData = questions?.filter((qId) => qId?.survey_id === id)

    useEffect(() => {
        fetchHeadSurvey()
    }, [])

    const getChoicesForQuestion = (questionId) => {
        return choices?.filter(choice => choice?.question_id === questionId);
    };

    const handleNextQuestion = (isBack) => {
        if (isBack && currentQuestion > 0) {
            setCurrentQuestion((prevQuestion) => prevQuestion - 1);
            return;
        }

        if (selectedOption.length === 0 && currentQuestion === questionData.length - 1) {
            message.success('Quiz completed!');
            // You may want to handle any final actions here
            return;
        }

        if (currentQuestion + 1 < questionData.length) {
            setCurrentQuestion((prevQuestion) => prevQuestion + 1);
            // Do not reset selected options array for the next question
        } else {
            onFeedback()
            message.success('Quiz completed!');
            // You may want to handle any final actions here
        }
    };

    const progressPercentage = ((currentQuestion + 1) / questionData.length) * 100;

    const fetchHeadSurvey = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/get-All-Out-Survey"}`);
            setSurveyHead(response?.data?.results)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    const title = surveyHead?.filter((qId) => qId?.surveys_id === id)

    const onFeedback = () => {

        setIsLoading(true)

        if (isLoading) return

        const apiUrl = `${SERVER_URL}survey-feedback/insert-for-out`;

        const mappedData = selectedOption?.map(option => ({
            category_id: option?.category_id,
            question_id: option?.question_id,
            choice_id: option?.choice_id,
            feedback_text: "",
        }));

        const filteredMappedData = mappedData.filter(item => item !== null);

        const requestData = {
            first_name: firstName || "",
            last_name: lastName || "",
            email: email || "",
            date_of_birth: date || "",
            gender: gender || "",
            other_details: otherDetail || "",
            survey_id: title?.[0]?.surveys_id,
            type_id: title?.[0]?.type_id || "",
            feedbacks: filteredMappedData,
        };

        try {

            axios.post(apiUrl, requestData)
                .then(response => {
                    // Handle the successful response
                    setIsLoading(false)
                    Swal.fire({
                        icon: "success",
                        title: "ທ່ານໄດ້ສົ່ງຄຳຕອບສຳເລັດເເລ້ວ",
                        // text: "Please login!",
                        allowOutsideClick: false,
                        confirmButtonAriaLabel: "Thumbs up, great!",
                        confirmButtonText: `Ok`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // handleclose()
                            navigate('/forms/finished')
                        }
                    });
                })
                .catch(error => {
                    // Handle errors
                    console.error('Error:', error);
                    setIsLoading(false)
                });

        } catch (error) {
            console.log("Error: ", error)
        }
    }

    function onChangeStartDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);

        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD")
        // Set the selected date in the new time zone
        setDate(bangkokTime);
        // console.log("=========>parsedDate", bangkokTime.format("YYYY-MM-DD HH:mm:ss"));
    }

    const onClickNext = () => {
        // Perform validation here
        if (!firstName || !lastName || !date || !gender) {
            // Handle validation failure, maybe show a message to the user
            errorSwal('ກາລຸນາປ້ອນຂໍ້ມູນສວ່ນຕົວ')
            return;
        }

        // If all fields are filled, proceed to the next step
        setIsForm(false);
    }

    const handleclose = () => {
        window.location.reload();
    }


    return (
        <>
            <div className='lg:hidden md:hidden m-3  '>

                <div className={`pl-2 pr-2 ${isForm === true ? "" : "h-[auto]"} bg-red    flex shadow-md  `}>
                    <div className={`${isForm === true ? "hidden" : ""} relative   max-sm:w-full   h-[auto] pb-[20px]  rounded-lg`}>
                        <div className='  absolute top-[-10px] w-full'>
                            <Progress
                                strokeLinecap="butt"
                                style={{ width: "100%" }}
                                showInfo={false}
                                percent={progressPercentage} />
                        </div>
                        <div className=' mt-3 p-[20px]'>
                            <div className=' text-center text-[16px] text-gray-900 font-bold' style={{ fontWeight: "bold" }}>
                                {currentQuestion + 1} ຈາກ {questionData.length}
                            </div>
                            <div className=' text-[16px] text-center mt-3 font-bold'>{surveyHead?.[0]?.title ?? "-"}</div>
                            <Divider />
                            <div className=' text-[14px] mt-5 font-medium'>ຄຳຖາມ :</div>
                            <div className=' text-[14px] mt-2 font-medium'>
                                {currentQuestion + 1}. {questionData[currentQuestion]?.question_text}
                                {/* {questionData[currentQuestion]?.question_id} */}
                            </div>
                            {
                                <Radio.Group
                                    // className=' mt-[25px]'
                                    onChange={(e) => setSelectedOption((prevOptions) => {
                                        const updatedOptions = [...prevOptions];
                                        // updatedOptions[currentQuestion] = e.target.value;
                                        updatedOptions[currentQuestion] = {
                                            question_id: questionData[currentQuestion]?.question_id,
                                            category_id: questionData[currentQuestion]?.category_id,
                                            choice_id: e.target.value,
                                        };
                                        return updatedOptions;
                                    })}
                                    value={selectedOption[currentQuestion]?.choice_id}
                                >
                                    {getChoicesForQuestion(questionData[currentQuestion]?.question_id)?.map(choice => (
                                        <div key={choice.choice_id}>
                                            <Radio className='p-2' value={choice.choice_id}>  {choice?.choice_text}</Radio>
                                        </div>
                                    ))}
                                </Radio.Group>
                            }
                        </div>
                        <div className='w-full  pl-[20px] pr-[20px]'>
                            <div className=' flex justify-center items-center'>

                                {currentQuestion <= 0 && (
                                    <Button
                                        className={` ${currentQuestion + 1 < questionData.length ? "" : "hidden"}   w-[50%] h-[40px] mr-[8px] `}
                                        onClick={() => setIsForm(true)}
                                    >
                                        ກັບຄືນຂໍ້ມູນສວ່ນຕົວ
                                    </Button>
                                )}

                                {currentQuestion > 0 && (
                                    <Button className=' w-[50%] h-[40px]' style={{ marginRight: 8 }} onClick={() => handleNextQuestion(true)}>
                                        ກັບຄືນ
                                    </Button>
                                )}

                                <Button type="" className={` ${currentQuestion + 1 < questionData.length ? "" : "hidden"}  bg-blue-600 w-[50%] h-[40px] text-white`} onClick={() => handleNextQuestion(false)}>
                                    ຕໍ່ໄປ
                                </Button>

                                <Button
                                    onClick={() => {
                                        const optionData = selectedOption?.filter(item => item !== undefined && item !== null);
                                        if (!optionData || optionData?.length !== questionData?.length) {
                                            warring('ກະລຸນາເລືອກຄຳຕອບໃຫ້ຄົບຖວ້ນທຸກຂໍ້')
                                        } else {
                                            onFeedback();
                                            // warring('successfully')
                                        }
                                    }}
                                    type="primary"
                                    className={` ${currentQuestion + 1 < questionData.length ? "hidden" : ""} bg-green-600  w-[50%] h-[40px] hover:bg-black`}>
                                    {isLoading ? <Spinner size='sm' /> : "ສຳເລັດ"}
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${isForm === true ? "" : "hidden"}  relative  max-sm:w-full rounded-lg`}>
                    <Form
                        layout="vertical"
                        initialValues={{
                            remember: true,
                            type_name: '',
                        }}
                    // onFinish={onCreateForm}
                    >

                        <div className='p-[35px]'>
                            <div className=' text-[20px] text-center mt-3 font-bold'>{surveyHead?.[0]?.title ?? "-"}</div>
                            <div className=' text-[12px] text-center mt-3 font-bold'>{surveyHead?.[0]?.descriptions ?? "-"}</div>
                            <Divider />

                            <div className=' text-[18px] mt-3 font-medium'>ຂໍ້ມູນສວ່ນຕົວ :</div>
                            <div className=' flex flex-col mt-3'>
                                <div className=' w-full'>
                                    <Form.Item
                                        label="ຊື່ເເທ້"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setFirstName(e.target.value)} className=' h-[40px]' />
                                    </Form.Item>
                                </div>
                                <div className=' w-full'>
                                    <Form.Item
                                        label="ນາມສະກຸນ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setLastName(e.target.value)} className=' h-[40px]' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className=' flex flex-col mt-1'>
                                <div className='w-full'>
                                    <Form.Item
                                        label="ອີເມວ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setEmail(e.target.value)} className=' h-[40px]' />
                                    </Form.Item>
                                </div>
                                <div className='w-full'>
                                    <Form.Item
                                        label="ວັນເດືອນປີເກີດ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <DatePicker onChange={onChangeStartDate} className=' w-full h-[40px]' placeholder='ເລຶອກວັນເດືອນປີເກີດ' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className=' flex mt-1'>
                                <div className='w-[50%]'>
                                    <Form.Item
                                        label="ເພດ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            defaultValue="male"
                                            className=' h-[40px]'
                                            onChange={(value) => setGender(value)}
                                            options={[
                                                {
                                                    value: 'male',
                                                    label: 'ຊາຍ',
                                                },
                                                {
                                                    value: 'female',
                                                    label: 'ຍິງ',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </div>
                                <div className=' ms-3 w-[50%]'>
                                    <Form.Item
                                        label="ລາຍລະອຽດເພີ່ມເຕີມ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setOtherDetail(e.target.value)} className=' h-[40px]' />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='w-full pl-[35px] pr-[35px] '>
                            <div className=' flex justify-center items-center'>
                                <Button
                                    type="primary"
                                    className={`${currentQuestion + 1 < questionData.length ? ' bg-blue-600' : ' bg-green-600'}   w-[100%] h-[40px]`}
                                    onClick={onClickNext}
                                >
                                    ຕໍ່ໄປ
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>

            </div>

            <div className={`max-sm:mt-3 max-sm:p-3 max-sm:hidden  h-screen flex flex-col overflow-scroll justify-center items-center bg-[#cbd5e1]`}>
                <div className={`${isForm === true ? "hidden" : ""}  relative  max-sm:w-full   w-[70%]  h-[auto] bg-white rounded-lg`}>
                    <div className='  absolute top-[-10px] w-full'>
                        <Progress
                            strokeLinecap="butt"
                            style={{ width: "100%" }}
                            showInfo={false}
                            percent={progressPercentage} />
                    </div>
                    <div className=' mt-3 p-[20px]'>
                        <div className=' text-center text-[16px] text-gray-900 font-bold' style={{ fontWeight: "bold" }}>
                            {currentQuestion + 1} ຈາກ {questionData.length}
                        </div>
                        <div className=' text-[20px] text-center mt-3 font-bold'>{title?.[0]?.title ?? "-"}</div>
                        {/* <div className=' text-[20px] text-center mt-3 font-bold'>{surveyHead?.[0]?.descriptions ?? "-"}</div> */}
                        <Divider />

                        <div className=' text-[18px] mt-5'>ຄຳຖາມ :</div>
                        <div className=' text-[18px] mt-2'>
                            {currentQuestion + 1}. {questionData[currentQuestion]?.question_text}
                            {/* {questionData[currentQuestion]?.question_id} */}
                        </div>
                        {
                            <Radio.Group
                                className=' mt-[25px]'
                                onChange={(e) => setSelectedOption((prevOptions) => {
                                    const updatedOptions = [...prevOptions];
                                    // updatedOptions[currentQuestion] = e.target.value;
                                    updatedOptions[currentQuestion] = {
                                        question_id: questionData[currentQuestion]?.question_id,
                                        category_id: questionData[currentQuestion]?.category_id,
                                        choice_id: e.target.value,
                                    };
                                    return updatedOptions;
                                })}
                                value={selectedOption[currentQuestion]?.choice_id}
                            >
                                {getChoicesForQuestion(questionData[currentQuestion]?.question_id)?.map(choice => (
                                    <div key={choice.choice_id}>
                                        <Radio className='p-2' value={choice.choice_id}>  {choice?.choice_text}</Radio>
                                    </div>
                                ))}
                            </Radio.Group>
                        }
                    </div>
                    <div className=' absolute bottom-3 w-full pr-[20px]'>

                        <div className=' flex justify-end items-center'>
                            {currentQuestion <= 0 && (
                                <Button
                                    className={` ${currentQuestion + 1 < questionData.length ? "" : "hidden"}   w-[20%] h-[40px] mr-3 `}
                                    onClick={() => setIsForm(true)}
                                >
                                    ກັບຄືນຂໍ້ມູນສວ່ນຕົວ
                                </Button>
                            )}

                            {currentQuestion > 0 && (
                                <Button className=' w-[20%] h-[40px]' style={{ marginRight: 8 }} onClick={() => handleNextQuestion(true)}>
                                    ກັບຄືນ
                                </Button>
                            )}

                            <Button type="" className={` ${currentQuestion + 1 < questionData.length ? "" : "hidden"}  bg-blue-600 w-[20%] h-[40px] text-white`} onClick={() => handleNextQuestion(false)}>
                                ຕໍ່ໄປ
                            </Button>

                            <Button
                                onClick={() => {
                                    const optionData = selectedOption?.filter(item => item !== undefined && item !== null);
                                    if (!optionData || optionData?.length !== questionData?.length) {
                                        warring('ກະລຸນາເລືອກຄຳຕອບໃຫ້ຄົບຖວ້ນທຸກຂໍ້')
                                    } else {
                                        onFeedback();
                                        // warring('successfully')
                                    }
                                }}
                                type=""
                                className={` ${currentQuestion + 1 < questionData.length ? "hidden" : ""} bg-green-600  w-[20%] h-[40px] text-white shadow-md `}>
                                {isLoading ? <Spinner size='sm' /> : "ສຳເລັດ"}
                            </Button>

                        </div>
                    </div>
                </div>
                <div className={`${isForm === true ? "" : "hidden"} bg-white  relative  max-sm:w-full pb-10   w-[70%]  h-[auto] rounded-lg shadow-md`} >
                    <Form
                        layout="vertical"
                        initialValues={{
                            remember: true,
                            type_name: '',
                        }}
                    // onFinish={onCreateForm}
                    >
                        <div className=' mt-3 p-[50px]'>
                            <div className=' text-[20px] text-center mt-3 font-bold'>{title?.[0]?.title ?? "-"}</div>
                            <div className=' text-[14px] text-center mt-3 '>{title?.[0]?.descriptions ?? "-"}</div>
                            <Divider />

                            <div className=' text-[18px] mt-5 font-medium'>ຂໍ້ມູນສວ່ນຕົວ :</div>
                            <div className=' flex mt-3'>
                                <div className=' w-[50%]'>
                                    <Form.Item
                                        label="ຊື່ເເທ້"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setFirstName(e.target.value)} className=' h-[40px]' />
                                    </Form.Item>
                                </div>
                                <div className=' ms-3 w-[50%]'>
                                    <Form.Item
                                        label="ນາມສະກຸນ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setLastName(e.target.value)} className=' h-[40px]' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className=' flex mt-1'>
                                <div className=' w-[50%]'>
                                    <Form.Item
                                        label="ອີເມວ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setEmail(e.target.value)} className=' h-[40px]' />
                                    </Form.Item>
                                </div>
                                <div className=' ms-3 w-[50%]'>
                                    <Form.Item
                                        label="ວັນເດືອນປີເກີດ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <DatePicker onChange={onChangeStartDate} className=' w-full h-[40px]' placeholder='ເລຶອກວັນເດືອນປີເກີດ' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className=' flex mt-1'>
                                <div className='w-[50%]'>
                                    <Form.Item
                                        label="ເພດ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            defaultValue="male"
                                            className=' h-[40px]'
                                            onChange={(value) => setGender(value)}
                                            options={[
                                                {
                                                    value: 'male',
                                                    label: 'ຊາຍ',
                                                },
                                                {
                                                    value: 'female',
                                                    label: 'ຍິງ',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </div>
                                <div className=' ms-3 w-[50%]'>
                                    <Form.Item
                                        label="ລາຍລະອຽດເພີ່ມເຕີມ"
                                        // name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setOtherDetail(e.target.value)} className=' h-[40px]' />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className=' w-full pr-[20px] '>
                            <div className=' flex justify-end items-center'>
                                <Button
                                    type=""
                                    className={`${currentQuestion + 1 < questionData.length ? ' bg-blue-600' : ' bg-green-600'} w-[20%] h-[40px]  text-white`}
                                    onClick={onClickNext}>
                                    ຕໍ່ໄປ
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    )
}

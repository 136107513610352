import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import axios from 'axios';

const ParticipanContext = createContext()

export default function ParticipansProvider({ children }) {

  const [participans, setParticipans] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchParticipansData()
  }, [])

  const fetchParticipansData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${SERVER_URL + "survey/participants/get-participants"}`, {
        headers: {
          // Authorization: `Bearer ${tokenData?.token}`,
        },
      });
      setParticipans(response?.data?.results);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    }
  };


  return (
    <ParticipanContext.Provider value={{ participans, setParticipans, loading }} >
      {children}
    </ParticipanContext.Provider>
  )
}

export function useParticipan() {
  return useContext(ParticipanContext)
}
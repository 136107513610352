import React, { createContext, useContext, useEffect, useState } from 'react';
import { tokenData } from '../constants';
import { SERVER_URL } from '../api';
import axios from 'axios';

const SurveyUserContext = createContext()

export default function SurveyUserProvider({ children }) {

    const [surveyUser, setSurveyUser] = useState([]);
    const [surveyUserOut, setSurveyUserOut] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchSurveyUserData()
        fetchSurveyUserOutData()
    }, [])

    const fetchSurveyUserData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/get-Survey-For-User"}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setSurveyUser(response?.data?.results);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    const fetchSurveyUserOutData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/get-All-Out-Survey"}`, {
                headers: {
                    // Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setSurveyUserOut(response?.data?.results);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };


    // console.log("===========>surveyUserOut", surveyUserOut)

    return (
        <SurveyUserContext.Provider value={{
            surveyUserOut,
            surveyUser,
            setSurveyUser,
            fetchSurveyUserData,
            fetchSurveyUserOutData,
            loading
        }} >
            {children}
        </SurveyUserContext.Provider>
    )
}

export function useSurveyUser() {
    return useContext(SurveyUserContext)
}

import React, { useState, useEffect } from 'react'
import { LeftOutlined } from '@ant-design/icons';
import { Button, message, Steps, theme, Alert, Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import Appbar from '../../components/Appbar';
import SurveyFormList from './SurveyFormList';
import EditSurveyQuestionList from './EditSurveyQuestionList';
import { successSwal } from '../../constants/sweetalert';
import { useSurvey } from '../../context/SurveyContext';

export default function EditSurvey() {


    const navigate = useNavigate()
    const { fetchSurveyData } = useSurvey()
    const [messageApi, contextHolder] = message?.useMessage();
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);

    const steps = [
        {
            title: 'ເເກ້ໄຂເເບບຟອມ',
            content: <SurveyFormList />
        },
        {
            title: 'ເເກ້ໄຂຄຳຖາມ',
            content: <EditSurveyQuestionList messageApi={messageApi} contextHolder={contextHolder} />
        }
    ];

    const contentStyle = {
        color: token.colorTextTertiary,
        borderRadius: token.borderRadiusLG,
        marginTop: 16,
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps?.map((item) => ({
        key: item?.title,
        title: item?.title,
    }));

    const onSuccessSurvey = async () => {
        await successSwal('ເເກ້ໄຂເເບບຟອມສຳເລັດ')
        fetchSurveyData()
        navigate('/survey-list')
    }

    return (
        <Appbar title={'ເເກ້ໄຂຟອມ'} link={'/survey-list'} icons={<LeftOutlined />}>
            <div className=' max-sm:mt-3 max-sm:pl-[20px] max-sm:pr-[20px]'>
                <Alert
                    message={<Breadcrumb
                        items={[
                            {
                                title: (
                                    <>
                                        <LeftOutlined />
                                        <span onClick={() => navigate('/survey-list')} className=' ms-2 cursor-pointer'>ກັບຄືນລາຍການຟອມ</span>
                                    </>
                                ),
                            },
                            {
                                title: 'ເເກ້ໄຂເເບບຟອມ',
                            },
                        ]}
                    />}
                    type="info"
                    closable={false}
                    className=' max-sm:hidden mb-3 shadow-sm hover:shadow-lg  shadow-slate-900 '
                />
                <Steps current={current} items={items} />
                <div style={contentStyle}>{steps[current]?.content}</div>
                <div
                    style={{
                        marginTop: 24,
                    }}
                >
                    {current < steps?.length - 0 && current !== 2 && current !== 1 && (
                        <Button
                            // disabled={!selectedTypeId}
                            className=' bg-blue-600 h-[40px]'
                            type="primary"
                            onClick={() => next()}>
                            ຕໍ່ໄປ
                        </Button>
                    )}

                    {current === steps?.length - 1 && current !== 3 && current !== 2 && (
                        <Button
                            // disabled={!questionData?.length > 0 ? true : false}
                            className={` bg-blue-600 h-[40px]`}
                            type="primary"
                            onClick={onSuccessSurvey}
                        >
                            ສຳເລັດ
                        </Button>
                    )}

                    {current > 0 && current !== 0 && (
                        <Button
                            className='h-[40px]'
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            ກັບຄືນ
                        </Button>
                    )}

                </div>
            </div>
        </Appbar>
    )
}

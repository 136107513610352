import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Badge, Nav, Navbar } from "react-bootstrap"
import { HomeOutlined, FileDoneOutlined, PieChartOutlined, SettingOutlined } from '@ant-design/icons';


export default function Footer() {

    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState('/dashboard');
    const selectedNavBottomIcon =

        useEffect(() => {
            // Retrieve the selectedTab from localStorage when the component mounts
            const storedTab = localStorage.getItem('selectedTab');
            if (storedTab) {
                setSelectedTab(storedTab);
            }
        }, []);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        navigate(tab);
        // Save the selectedTab to localStorage
        localStorage.setItem('selectedTab', tab);
    };

    return (
        // <div>
        <Navbar fixed="bottom" className="p-0 m-0 d-block d-lg-none d-md-none d-flex h-20 justify-content-between font-size-14 bg-gradient-to-r from-gray-900 via-gray-900 to-blue-900" style={{ color: '#6B7280' }}>
            <div style={{ height: 50 }} />
            <Nav.Link className={`d-flex flex-column align-items-center px-4 py-2`} style={{ color: selectedNavBottomIcon === '' && '#FFF', backgroundColor: selectedNavBottomIcon === '' && 'rgba(255, 255, 255, 0.10)' }} onClick={() => window.location.href = "http://next.phongsavanhgroup.com"}>
                <HomeOutlined style={{ fontSize: 25 }} />
                {selectedTab === '' && <div>ໜ້າຫຼັກ</div>}
            </Nav.Link>
            <div className="d-flex flex-column align-items-center px-4 py-2 cursor-pointer" style={{ color: selectedTab === '/dashboard' && '#FFF', backgroundColor: selectedTab === '/dashboard' && 'rgba(255, 255, 255, 0.10)' }} onClick={() => handleTabClick('/dashboard')}>
                <PieChartOutlined style={{ fontSize: 25 }} />
                {selectedTab === '/dashboard' && <div>ລາຍງານ</div>}
            </div>
            <Nav.Link className="d-flex flex-column align-items-center px-4 py-2" style={{ color: selectedTab === '/survey-list' && '#FFF', backgroundColor: selectedTab === '/survey-list' && 'rgba(255, 255, 255, 0.10)' }} onClick={() => handleTabClick('/survey-list')}>
                <FileDoneOutlined style={{ fontSize: 25 }} />
                {selectedTab === '/survey-list' && <div>ເເບບຟອມ</div>}
            </Nav.Link>
            <Nav.Link className="d-flex flex-column align-items-center px-4 py-2" style={{ color: selectedTab === '/question' && '#FFF', backgroundColor: selectedTab === '/question' && 'rgba(255, 255, 255, 0.10)' }} onClick={() => handleTabClick('/question')}>
                <FileDoneOutlined style={{ fontSize: 25 }} />
                {selectedTab === '/question' && <div>ຕອບຄຳຖາມ</div>}
            </Nav.Link>
        </Navbar>
        // </div >
    )
}

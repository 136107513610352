import React, { useEffect } from 'react'
import { useRoutes, useNavigate, useLocation } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import SurveyList from '../pages/survey/SurveyList';
import CreateSurveyList from '../pages/survey/CreateSurveyList';
import Dashboard from '../pages/dashboard/Dashboard';
import Question from '../pages/question/Question';
import AddSurvey from '../pages/survey/AddSurvey';
import QuestionList from '../pages/question/QuestionList';
import QuestionOut from '../pages/question/QuestionOut';
import QuestionResult from '../pages/question/QuestionResult';
import EditSurvey from '../pages/survey/EditSurvey';
import SurveyDetail from '../pages/survey/SurveyDetail';
import SurveyDetailIn from '../pages/survey/SurveyDetailIn';


export default function Router() {

    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        if (location?.pathname === "/") {
            // If the current path is "/", redirect to "/dashboard"
            navigate("/survey-list");
        }
    }, [location.pathname, navigate]);

    return useRoutes([
        {
            element: <PrivateRoute />,
            children: [
                {
                    path: "/dashboard",
                    element: <Dashboard />,
                },
                {
                    path: "/survey-list",
                    element: <SurveyList />,
                },
                {
                    path: "/create-survey-list",
                    element: <CreateSurveyList />,
                },
                {
                    path: "/question",
                    element: <Question />,
                },
                {
                    path: "/private/forms/:id",
                    element: <QuestionList />,
                },
                {
                    path: "/add-survey",
                    element: <AddSurvey />,
                },
                {
                    path: "/edit-survey/:id",
                    element: <EditSurvey />,
                },
                {
                    path: "/survey-detail/:id",
                    element: <SurveyDetail />,
                },
                {
                    path: "/survey-detail-in/:id",
                    element: <SurveyDetailIn />,
                },
            ]
        },
        {
            path: "/forms/finished",
            element: <QuestionResult />,
        },
        {
            path: "/forms/:id",
            element: <QuestionOut />,
        },
        {
            path: '*',
            element: <h1>404</h1>
        },
    ])
}

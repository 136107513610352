import React, { useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import { Radio, List, Button, Card, Skeleton } from 'antd';
import { useType } from '../../context/TypeContext';

export default function TypeList({ handleDelete, handleRadioChange, setCreateType, selectedTypeId }) {

    const { types, loading } = useType()

    return (
        <div>
            <Card
                bordered={false}
            >
                <div className='flex  items-center'>
                    <Button
                        onClick={() => setCreateType(true)}
                        className=' bg-blue-500 h-[40px]'
                        type="primary"
                        icon={<PlusOutlined />}>
                        ເພີ່ມໃໜ່
                    </Button>
                    <div className='ms-2 text-[14px]'>ເລືອກປະເພດຟອມ</div>
                </div>
                <Skeleton
                    className='mt-4'
                    loading={loading}
                    paragraph={{
                        rows: 10,
                    }}
                    active List>
                    <List
                        className=' mt-3'
                        itemLayout="horizontal"
                        dataSource={types || []}  // Use an empty array if types is null
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Button onClick={handleDelete}>ລຶບ</Button>}
                                    title={
                                        <Radio.Group onChange={handleRadioChange} value={selectedTypeId}>
                                            <Radio value={item?.type_id}>
                                                <a>{item?.type_name}</a>
                                            </Radio>
                                        </Radio.Group>
                                    }
                                // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                />
                            </List.Item>
                        )}
                    />
                </Skeleton>
            </Card>
        </div>
    )
}

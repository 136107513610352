import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate, Navigate } from "react-router-dom"
import MainLayout from '../components/MainLayout';
import Navbar from '../components/Navbar';
import Swal from 'sweetalert2';
import Footer from '../components/Footer';
import { tokenData } from '../constants';
import { SERVER_URL } from '../api';

export default function PrivateRoute() {
    const [username, setUsername] = useState('');
    const setCookie = (name, value, days) => {
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = `expires=${expirationDate.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/`;
    };

    const handleButtonClick = async (inputValue) => {
        // setIsLoading(true);
        try {
            const response = await fetch(`${SERVER_URL}login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: "userhq1",
                    // username: "jane",
                    // username: inputValue,
                    password: "123456",
                }),
            });

            if (!response.ok) {
                throw new Error('Authentication failed');
            }

            const data = await response.json();
            window.location.reload();
            setCookie('user', JSON.stringify(data));

        } catch (error) {
            console.error('Login error', error);
        } finally {
            // setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!tokenData) {
            Swal.fire({
                icon: "info",
                title: "ກາລຸນາເຂົ້າສູ່ລະບົບ",
                // input: 'text', // Add input field
                // inputPlaceholder: 'Enter Username', // Placeholder text for the input field
                inputValue: username,
                // text: "Please login!",
                allowOutsideClick: false,
                confirmButtonAriaLabel: "Thumbs up, great!",
                confirmButtonText: `ເຂົ້າສູ່ລະບົບ`,
            }).then((result) => {
                if (result.isConfirmed) {
                    // const inputValue = result.value;
                    // handleButtonClick(inputValue); // Call handleButtonClick on OK button click
                    // handleButtonClick(); // Call handleButtonClick on OK button click
                    window.location.href = "http://next.phongsavanhgroup.com"
                }
            });
        }
    }, [tokenData])

    return (
        <div className='bg-[#f1f5f9]'>
            <div className='sm:hidden max-sm:hidden md:flex lg:flex'>
                <MainLayout>
                    <Outlet />
                </MainLayout>
            </div>

            <div className='lg:hidden xl:hidden 2xl:hidden relative  w-full bg-[#f1f5f9]'>
                <div className=' h-screen overflow-scroll'>
                    <Outlet />
                </div>
                <div className=' fixed bottom-0 w-full'>
                    {/* <Navbar /> */}
                    <Footer />
                </div>

            </div>
        </div>
    );
}

// className = ' bg-[#f1f5f9]'
import React, { useState, useEffect } from 'react'
import { LeftOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons';
import { Result, Tag, List, Button, Card, Skeleton, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { tokenData } from '../../constants';
import moment from 'moment';
import { SERVER_URL } from '../../api';
import EditSurveyForm from './EditSurveyForm';

export default function SurveyFormList() {

    const { id } = useParams()

    const [editSurvey, setEditSurvey] = useState(false)
    const [editSurveyData, setEditSurveyData] = useState([])
    const [loading, setLoding] = useState(false)

    useEffect(() => {
        fetchSurvey()
    }, [])

    const fetchSurvey = async () => {
        setLoding(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/get-survey/" + id}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setEditSurveyData(response?.data?.results)
            setLoding(false)
        } catch (error) {
            setLoding(false)
            console.log("Error: ", error)
        }
    }

    const columns = [
        {
            title: 'ຫົວເລື່ອງ',
            dataIndex: 'title',
            key: 'title',
            //   render: (text) => <a>{text}</a>,
            ellipsis: true,
        },
        {
            title: 'ລາຍລະອຽດ',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
        },
        {
            title: 'ວັນທີເລີ່ມ',
            dataIndex: 'startDate',
            key: 'startDate',
            ellipsis: true,
        },
        {
            title: 'ຫາວັນທີ',
            dataIndex: 'EndDate',
            key: 'EndDate',
            ellipsis: true,
        },
        {
            title: 'ຮູບເເບບ',
            dataIndex: 'Type',
            key: 'Type',
            ellipsis: true,
        },
    ];

    const data = editSurveyData?.map((item) => (
        {
            title: item?.title,
            description: item?.descriptions,
            startDate: moment(item?.start_date)?.format("DD-MM-YYYY"),
            EndDate: moment(item?.end_date)?.format("DD-MM-YYYY"),
            Type: <Tag className=' p-2 text-[14px]' color={item?.surveys_status === "in" ? "blue" : "#f50"}>{item?.surveys_status === "in" ? "ເເບບຟອມພາຍໃນ" : "ເເບບຟອມພາຍນອກ"}</Tag>,
            is_active: item?.is_active
        }
    ))

    // console.log("=========>data", editSurveyData)

    return (
        <div>

            <Card bordered={false}>

                <div className=' flex items-center'>
                    <Button
                        // disabled={surveyLates && surveyLates.length >= 1}
                        onClick={() => setEditSurvey(true)}
                        className={` bg-blue-500 h-[40px]`}
                        type="primary"
                        icon={<FormOutlined />}>
                        ເເກ້ໄຂເເບບຟອມ
                    </Button>
                </div>

                {/* <div className=' mt-4'>
                    <List
                        // className={`${index === 0 ? '' : 'mt-3'}`}
                        size="small"
                        // bordered
                        dataSource={editSurveyData} // You need to provide an array for the dataSource prop
                        renderItem={(item, index) => (
                            <>
                                <List.Item>
                                    <div className='flex w-full bg-red'>
                                        <div className='w-[100%]'>ຫົວເລື່ອງ</div>
                                        <div className='w-[100%]'>{item?.title}</div>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className='flex w-full'>
                                        <div className='w-[100%]'>ລາຍລະອຽດ</div>
                                        <div className='w-[100%]'>{item?.descriptions}</div>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className='flex w-full'>
                                        <div className='w-[100%]'>ວັນທີເລີ່ມ</div>
                                        <div className='w-[90%]'>{moment(item?.start_date)?.format("DD-MM-YYYY")}</div>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className='flex w-full'>
                                        <div className='w-[10%]'>ຫາວັນທີ</div>
                                        <div className='w-[90%]'>{moment(item?.end_date)?.format("DD-MM-YYYY")}</div>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className='flex w-full'>
                                        <div className='w-[10%]'>ຮູບເເບບ</div>
                                        <div className='w-[90%]'>
                                            {item?.surveys_status === "in" ? "ເເບບຟອມພາຍໃນ" : "ເເບບຟອມພາຍນອກ"}
                                        </div>
                                    </div>
                                </List.Item>
                            </>
                        )}
                    />
                </div> */}
                <Skeleton className=' mt-3' loading={loading} active Table>
                    <Table scroll={{ x: true }} className=' mt-3' columns={columns} dataSource={data} />
                </Skeleton>

            </Card>

            <EditSurveyForm
                isModalOpen={editSurvey}
                handleCancel={() => setEditSurvey(false)}
                editSurveyData={editSurveyData}
                fetchSurvey={fetchSurvey}
            />

        </div>
    )
}

import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function Appbar({ children, title, icons, link }) {
    const navigate = useNavigate();

    return (
        <div className='relative '>
            <div className={` lg:hidden  md:hidden fixed z-50 w-full bg-gradient-to-r from-gray-900 via-gray-900 to-blue-900 h-[60px] flex items-center px-3  text-white`}>
                <div>
                    {/* Add a back button if needed */}
                    {
                        icons && (
                            <span className=' cursor-pointer' onClick={() => navigate(link)}> {icons}</span>
                        )
                    }
                </div>
                <div className={`flex-1 ${icons ? "text-center" : "text-center"}`}>{title}</div>
            </div>
            <div className=' max-sm:pt-[60px] max-sm:pb-[100px] '>
                {children}
            </div>
        </div>
    );
}

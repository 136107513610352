const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
};

const tokenString = getCookie('user');

// Decode the URL-encoded string
const decodedTokenString = decodeURIComponent(tokenString);

export const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;


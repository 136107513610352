import React, { useEffect, useState } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Input, DatePicker, Radio, Form, Space } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SERVER_URL } from '../../api';
import { tokenData } from '../../constants';
import { Spinner } from 'react-bootstrap';
import { successSwal } from '../../constants/sweetalert';
import { useSurvey } from '../../context/SurveyContext';
import useWindowDimensions from '../../constants/useWindowDimensions';
dayjs.extend(utc);
const { TextArea } = Input;

export default function EditSurveyForm({ isModalOpen, handleCancel, editSurveyData, fetchSurvey }) {


    const { width } = useWindowDimensions()
    const { fetchSurveyData } = useSurvey()
    const [title, setTitle] = useState()
    const [descriptions, setDescriptions] = useState()
    const [value, setValue] = useState();
    const [isActive, setIsActive] = useState()
    const [currentStartDate, setCurrentStartDate] = useState(null)
    const [currentEndDate, setCurrentEndDate] = useState(null)
    const [isUsing, setIsUsing] = useState()
    const [isLoading, setIsLoading] = useState()
    const [eUsing, setEUsing] = useState(true)
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemsDepartment, setSelectedItemsDepartment] = useState([]);

    useEffect(() => {
        if (editSurveyData) {
            setTitle(editSurveyData?.[0]?.title)
            setDescriptions(editSurveyData?.[0]?.descriptions)
            setValue(editSurveyData?.[0]?.surveys_status)
            setIsActive(editSurveyData?.[0]?.is_active)
            const formattedStartDate = dayjs(editSurveyData?.[0]?.start_date);
            const formattedEndDate = dayjs(editSurveyData?.[0]?.end_date);
            setCurrentStartDate(formattedStartDate)
            setCurrentEndDate(formattedEndDate)
        }
    }, [editSurveyData])

    const onUpdateForm = async (values) => {

        setIsLoading(true)

        try {
            const url = `${SERVER_URL}survey/update/${editSurveyData?.[0]?.surveys_uid}`;

            // Map the selected branch IDs to the desired structure
            const formattedBranchs = selectedItems.map(branchId => ({ branch_id: String(branchId) }));
            const formattedDepartMent = selectedItemsDepartment.map(departmentId => ({ department_id: String(departmentId) }));

            const data = {
                // ...values,
                title: title,
                descriptions: descriptions,
                start_date: currentStartDate,
                end_date: currentEndDate,
                surveys_status: value,
                is_active: isActive,
                branchs: formattedBranchs,
                departments: formattedDepartMent
            }

            const response = await axios.put(url, data, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                },
            });

            successSwal('ອັບເດດສຳເລັດ')
            fetchSurvey()
            fetchSurveyData()
            setIsLoading(false)
            handleCancel()

        } catch (error) {
            console.log("Error: ", error)
            setIsLoading(false)
        }
    }

    const onChange = (e) => {
        // console.log('radio checked', e.target.value);
        setValue(e.target.value);
        // Update eUsing based on the selected value
        setEUsing(e.target.value === "in");
    };

    function onChangeStartDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);

        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD")
        // Set the selected date in the new time zone
        setCurrentStartDate(bangkokTime);
    }

    function onChangeEndDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);

        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD")
        // Set the selected date in the new time zone
        setCurrentEndDate(bangkokTime);
    }

    return (
        <Modal
            title="ເເກ້ໄຂເເບບຟອມ"
            open={isModalOpen}
            // onOk={onCreateType}
            transitionName={false}
            width={width > 700 ? '50%' : "100%"}
            centered
            okText={false}
            footer={[]}
            onCancel={handleCancel}>
            <Form
                layout="vertical"
                initialValues={{
                    remember: true,
                    title: title,
                    descriptions: descriptions,
                }}
                onFinish={onUpdateForm}
            >
                <div className=' pt-[10px] pb-5'>

                    <Form.Item label="ຫົວເລື່ອງ" rules={[{ required: true, message: 'ກະລຸນາປ້ອນຫົວເລື່ອງ!' }]} tooltip="ນີ້ເເມ່ນຫົວເລື່ອງ">
                        <TextArea
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            rows={4} />
                    </Form.Item>

                    <Form.Item label="ລາຍລະອຽດ" rules={[{ required: true, message: 'ກະລຸນາປ້ອນລາຍລະອຽດ!' }]} tooltip="ນີ້ເເມ່ນລາຍລະອຽດ">
                        <TextArea
                            value={descriptions}
                            onChange={(e) => setDescriptions(e.target.value)}
                            rows={4} />
                    </Form.Item>

                    <div className=' flex justify-between mt-4'>
                        <div className=' w-full'>
                            <Form.Item label="ເລີ່ມວັນທີ" rules={[{ required: true, message: 'ກະລຸນາເລືອກວັນທີເລີ່ມ!' }]} tooltip="ນີ້ເເມ່ນວັນທີເລີ່ມ">
                                <DatePicker
                                    // format="DD-MM-YYYY"
                                    value={currentStartDate}
                                    onChange={onChangeStartDate}
                                    className=' w-full h-[40px]'
                                    allowClear={false}
                                    placeholder='ເລືອກວັນທີ' />
                            </Form.Item>
                        </div>
                        <div className=' w-full'>
                            <Form.Item label="ຫາວັນທີ" rules={[{ required: true, message: 'ກະລຸນາເລືອກວັນທີສິ້ນສຸດ!' }]} tooltip="ນີ້ເເມ່ນວັນທີສິ້ນສຸດ">
                                <DatePicker
                                    // format="DD-MM-YYYY"
                                    value={currentEndDate}
                                    onChange={onChangeEndDate}
                                    className=' ms-2 w-full h-[40px]'
                                    placeholder='ເລືອກວັນທີ'
                                    allowClear={false}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {/* <Form.Item name="surveys_status" rules={[{ required: true, message: 'ກະລຸນາເລືອກຮູບເເບບຟອມ!' }]} tooltip="ນີ້ເເມ່ນຮູບເເບບຟອມ">
                    <Radio.Group
                        className=' mt-4'
                        onChange={onChange}
                        value={value}>
                        <Radio value={'in'}>ເເບບຟອມພາຍໃນ</Radio>
                        <Radio value={'out'}>ເເບບຟອມພາຍນອກ</Radio>
                    </Radio.Group>
                </Form.Item> */}

                    {/* <Form.Item name="isUsing" rules={[{ required: true, message: 'ກະລຸນາເລືອກຮູບເເບບຟອມ!' }]} tooltip="ນີ້ເເມ່ນຮູບເເບບຟອມ"> */}
                    {/* <div className={`${value === "out" || !value ? "hidden" : ""}`}>
                    <Radio.Group
                        className=' mt-3'
                        onChange={(e) => setIsUsing(e.target.value)}
                        value={isUsing}>
                        <Space direction="vertical" >
                            <Radio value={'All'}>ທັງໜົດ</Radio>
                            <Radio value={'Selected'}>ສະເພາະທີ່ເລຶອກ</Radio>
                        </Space>
                    </Radio.Group>
                </div> */}
                    {/* </Form.Item> */}
                </div>
                <div className=' flex justify-end'>
                    <Button
                        onClick={() => handleCancel()}
                        className=' h-[40px]'
                        // type="primary"
                        icon={<CloseOutlined />}>
                        ຍົກເລິກ
                    </Button>
                    <Button
                        htmlType="submit"
                        className=' bg-blue-600 h-[40px] ms-2'
                        type="primary"
                        icon={<CheckOutlined />}>
                        {isLoading ? <Spinner size='sm' /> : "ບັນທືກ"}
                    </Button>
                </div>
            </Form>
        </Modal >
    )
}

import React, { useState } from 'react';
import { PlusCircleOutlined, DeleteOutlined, StarOutlined, CloseOutlined, CheckOutlined, SaveOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Radio, Typography, Divider } from 'antd';
import Form from 'react-bootstrap/Form';
import { errorSwal, successSwal } from '../../constants/sweetalert';
const { Paragraph, Text } = Typography;

export default function AddQuestion({ show, handleClose, setQuestionsArray }) {

    const [quest, setQuest] = useState('')
    const [answer, setAnswer] = useState([]);
    const [answerName, setAnswerName] = useState('');
    const [score, setScore] = useState('')
    const [editIndex, setEditIndex] = useState(null);
    const [answerType, setAnswerType] = useState(1);
    const [idCounter, setIdCounter] = useState(1); // Initialize the ID counter

    let startsWithLetterOrNumber;

    // const handleAddAnswer = () => {
    //     if (answerName.trim() !== '' && score.trim() !== '') {
    //         if (editIndex !== null) {
    //             // Editing existing answer
    //             const updatedAnswer = [...answer];
    //             updatedAnswer[editIndex] = answerType === 1 ? { answer: `${String.fromCharCode(65 + editIndex)}. ${answerName}`, score: score } : { answer: `${editIndex + 1}. ${answerName}`, score: score };
    //             setAnswer(updatedAnswer);
    //             setEditIndex(null);
    //         } else {
    //             // Adding a new answer
    //             const newAnswer = answerType === 1 ? { answer: `${String.fromCharCode(65 + answer.length)}. ${answerName}`, score: score } : { answer: `${answer.length + 1}. ${answerName}`, score: score };;
    //             setAnswer((prevAnswer) => [...prevAnswer, newAnswer]);
    //         }

    //         setAnswerName(''); // Clear input after adding/editing to the array
    //         setScore('')
    //     }
    // };

    const handleAddAnswer = () => {
        if (answerName.trim() === '') {
            return;
        }

        if (score.trim() === '') {
            return;
        }

        if (editIndex !== null) {
            // Editing existing answer
            const updatedAnswer = [...answer];
            updatedAnswer[editIndex] = answerType === 1 ? { answer: `${String.fromCharCode(65 + editIndex)}. ${answerName}`, score: score } : { answer: `${editIndex + 1}. ${answerName}`, score: score };
            setAnswer(updatedAnswer);
            setEditIndex(null);
        } else {
            // Adding a new answer
            const newAnswer = answerType === 1 ? { answer: `${String.fromCharCode(65 + answer.length)}. ${answerName}`, score: score } : { answer: `${answer.length + 1}. ${answerName}`, score: score };
            setAnswer((prevAnswer) => [...prevAnswer, newAnswer]);
        }

        setAnswerName(''); // Clear input after adding/editing to the array
        setScore('');
    };

    const handleEditAnswer = (index) => {
        setEditIndex(index);
        // Check if the existing answer starts with a letter or a number
        const existingAnswer = answer[index];
        const matchLetter = typeof existingAnswer === 'object' && existingAnswer.answer.match(/^[A-Z]\.\s+/);
        const matchNumber = typeof existingAnswer === 'object' && existingAnswer.answer.match(/^\d+\.\s+/);

        if (matchLetter) {
            // Editing an answer starting with a letter
            setAnswerName(existingAnswer.answer.replace(/^[A-Z]\.\s+/, ''));
        } else if (matchNumber) {
            // Editing an answer starting with a number
            setAnswerName(existingAnswer.answer.replace(/^\d+\.\s+/, ''));
        } else {
            // Handle other cases as needed
            // For example, you might default to editing as if it starts with a letter
            setAnswerName(existingAnswer.answer);
        }
        setScore(existingAnswer.score);
    };

    const handleCancelEdit = () => {
        setEditIndex(null);
        setAnswerName('');
    };

    const handleDeleteAnswer = (index) => {
        const newAnswer = [...answer];
        newAnswer.splice(index, 1);

        // Update the answer state with objects
        setAnswer(newAnswer.map((ans, idx) => ({ answer: `${String.fromCharCode(65 + idx)}. ${ans.answer.replace(/^[A-Z]\.\s+/, '')}`, score: ans.score })));

        setEditIndex(null);
    };

    const onHandleSave = async () => {

        if (quest && answer.length > 0) {

            const totalScore = answer.reduce((acc, ans) => acc + parseInt("200"), 0); // Change "200" to the actual score

            const formattedAnswers = answer.map((ans, index) => ({
                answer: ans?.answer,
                score: ans?.score, // You can set the score based on your requirements
            }));

            // Create a new object with the desired format
            const newData = {
                id: idCounter.toString(),
                quantityScore: totalScore,
                question: quest,
                answer: formattedAnswers,
            };

            // Update state or do whatever you need with the newData
            // For example, pushing it to an array
            setQuestionsArray((prevArray) => [...prevArray, newData]);

            // Increment the ID counter for the next entry
            setIdCounter((prevCounter) => prevCounter + 1);

            // Clear current question and answer
            handleClose()
            successSwal('ເພີ່ມຄຳຖາມສຳເລັດ')
            setQuest('');
            // setAnswer([]);

        } else {
            errorSwal('ກາລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບ !')
            console.error("Question or answer is missing.");
        }
    };

    const onClose = () => {
        setQuest('')
        setScore('')
        setAnswer([])
        handleClose()
    }

    return (
        <Modal
            centered
            open={show}
            transitionName={false}
            className='p-0 m-0'
            margin={0}
            width={700}
            closeIcon={false}
            footer={[
                <div className='mt-5 h-[100px] flex justify-center items-center'>
                    <Button
                        icon={<CloseOutlined />}
                        onClick={onClose}>
                        ຍົກເລິກ
                    </Button>
                    <Button
                        onClick={() => onHandleSave()}
                        icon={<CheckOutlined />}
                        type="primary"
                        className='bg-blue-600'
                    >
                        ບັນທືກ
                    </Button>
                </div>
            ]}
            onCancel={onClose}>

            <div className=' bg-blue-600 text-[#FFFFFF] w-full text-[25px] h-[70px] text-center flex justify-center items-center '>
                ເພີ່ມຄຳຖາມ
            </div>

            <div className='text-[16px] mt-[20px]'>ເລືອກຮູບເເບບຄຳຕອບ</div>

            <div className='mt-3'>
                <Radio.Group
                    value={answerType}
                    onChange={(e) => setAnswerType(e.target.value)}
                >
                    <Radio value={1} style={{ fontSize: 16 }}>A B C D</Radio>
                    <Radio value={2} style={{ fontSize: 16 }}>1 2 3 4</Radio>
                </Radio.Group>
            </div>

            <Form.Label className='mt-4 text-[16px]'>ຄຳຖາມ</Form.Label>

            <Form.Control
                name='quest'
                value={quest}
                onChange={(e) => setQuest(e.target.value)}
                as="textarea"
                rows={3} />

            <Form.Label className='mt-4 text-[16px]'>ຄຳຕອບ</Form.Label>
            <div>

            </div>

            {/* Display the added answers */}
            <div>
                {answer.map((item, index) => (
                    <>
                        <Divider className='mt-[20px]' />
                        <div key={index} className=' flex justify-between w-full items-center'>
                            {/* {index === editIndex ? (
                                <Form.Control
                                    value={answerName}
                                    onChange={(e) => setAnswerName(e.target.value)}
                                />
                            ) : (
                                item
                            )} */}
                            <div className='w-[80%]'>
                                <Paragraph className='text-[16px]' level={5}>
                                    {/* Use the variable inside the map function */}
                                    {startsWithLetterOrNumber = /^([A-Z]\.|\d+\.\s+)/.test(item?.answer) ? (
                                        <span>
                                            <mark className='text-[25px]'>{item?.answer?.substring(0, 3)}</mark>&ensp;
                                            {item?.answer?.substring(3)}
                                        </span>
                                    ) : (
                                        item?.answer
                                    )}
                                </Paragraph>
                            </div>
                            <div className='w-[20%]'>
                                <StarOutlined className='text-[15px] pr-[5px]' />
                                {item?.score}
                                <Button
                                    type="danger"
                                    icon={index === editIndex ? <SaveOutlined className=' text-green-600' /> : <EditOutlined />}
                                    onClick={() => (index === editIndex ? handleAddAnswer() : handleEditAnswer(index))}
                                    className='ml-[10px]'
                                />
                                <Button
                                    type="danger"
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleDeleteAnswer(index)}
                                    className='ml-[10px]'
                                />
                            </div>
                            <div>

                            </div>
                        </div>
                    </>

                ))}
            </div>

            <Divider />
            <div className={`row ${answer?.length > 0 ? "mt-[20px]" : ""}`}>
                <div className='col-8'>
                    <Form.Control
                        type='number'
                        disabled={quest === "" ? true : false}
                        name='answerName'
                        value={score}
                        placeholder={quest === "" ? "" : "ກາລຸນາປ້ອນຄະເເນນ"}
                        isInvalid={score.trim() === ""}
                        onChange={(e) => setScore(e.target.value)} />
                    <Form.Control
                        className='mt-2'
                        disabled={quest === "" ? true : false}
                        name='answerName'
                        as="textarea"
                        value={answerName}
                        placeholder={quest === "" ? "ກາລຸນາເພີ່ມຄຳຖາມກອ່ນ !" : ""}
                        isInvalid={answerName.trim() === ""}
                        onChange={(e) => setAnswerName(e.target.value)}
                        rows={4} />
                </div>
                <div className='col-4'>
                    {editIndex !== null ? (
                        <div className='w-full flex'>
                            <Button
                                className=' h-[40px] w-[50%] bg-green-600 text-[#FFFFFF]'
                                icon={<SaveOutlined />}
                                onClick={handleAddAnswer}
                            >
                                ອັບເດດ
                            </Button>
                            <Button
                                className='h-[40px] ml-[5px] w-[50%]'
                                onClick={handleCancelEdit}
                            >
                                ຍົກເລິກ
                            </Button>
                        </div>
                    ) : (
                        <Button
                            className='h-[40px] w-[100%]'
                            icon={<PlusCircleOutlined />}
                            onClick={handleAddAnswer}
                        >
                            {editIndex !== null ? 'ອັບເດດ' : 'ເພີ່ມຄຳຕອບ'}
                        </Button>
                    )}
                </div>
                <div className='col-4 mt-2 hidden'>
                    {editIndex !== null ? (
                        <div className=' flex w-full'>
                            <Button
                                className='h-[40px] w-[50%] bg-green-600 text-[#FFFFFF]'
                                icon={<SaveOutlined />}
                                onClick={handleAddAnswer}
                            >
                                ອັບເດດ
                            </Button>
                            <Button
                                className='h-[40px] ml-[5px] w-[50%]'
                                onClick={handleCancelEdit}
                            >
                                ຍົກເລິກ
                            </Button>
                        </div>
                    ) : (
                        <Button
                            className='h-[40px] w-full'
                            icon={<PlusCircleOutlined />}
                            onClick={handleAddAnswer}
                        >
                            {editIndex !== null ? 'ອັບເດດ' : 'ເພີ່ມຄຳຕອບ'}
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    )
}

import React, { useState, useEffect } from 'react';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Alert, Radio, Breadcrumb, Progress, Divider, Spin } from 'antd';
import Appbar from '../../components/Appbar';
import { tokenData } from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuestions } from '../../context/QuestionContext';
import { useChoice } from '../../context/ChoiceContext';
import { SERVER_URL } from '../../api';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import QuestionOut from './QuestionOut';
import { successSwal, warring } from '../../constants/sweetalert';
import { useSurveyUser } from '../../context/SurveyUserContext';

export default function QuestionList() {

    const { id } = useParams()
    const navigate = useNavigate()
    const { fetchSurveyUserData } = useSurveyUser()
    const { choices, loading: loadingChoice } = useChoice()
    const { questions } = useQuestions()
    const [surveyHead, setSurveyHead] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const questionData = questions?.filter((qId) => qId?.survey_id === id)

    useEffect(() => {
        fetchHeadSurvey()
    }, [])

    useEffect(() => {
        if (surveyHead?.[0]?.surveys_status === "out") {
            onAnswer(id)
        }
    }, [surveyHead])

    const getChoicesForQuestion = (questionId) => {
        return choices?.filter(choice => choice?.question_id === questionId);
    };

    const handleNextQuestion = async (isBack) => {
        if (isBack && currentQuestion > 0) {
            setCurrentQuestion((prevQuestion) => prevQuestion - 1);
            return;
        }

        if (currentQuestion + 1 < questionData.length) {
            setCurrentQuestion((prevQuestion) => prevQuestion + 1);
            // Do not reset selected options array for the next question
        } else if (selectedOption?.length !== questionData?.length) {
            warring('ກະລຸນາເລືອກຄຳຕອບໃຫ້ຄົບຖວ້ນທຸກຂໍ້')
        } else {
            // onFeedback()
        }

    };

    const progressPercentage = ((currentQuestion + 1) / questionData.length) * 100;

    const fetchHeadSurvey = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "survey/get-survey/" + id}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                },
            });
            setSurveyHead(response?.data?.results)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    const onFeedback = async () => {

        setIsLoading(true)

        if (isLoading) return

        const apiUrl = `${SERVER_URL}survey-feedback/insert`;

        const mappedData = selectedOption?.map(option => ({
            category_id: option?.category_id,
            question_id: option?.question_id,
            choice_id: option?.choice_id,
            feedback_text: "", // If feedback_text is available in the selectedOption, include it; otherwise, include an empty string
        }));

        const filteredMappedData = mappedData.filter(item => item !== null);

        const requestData = {
            survey_id: surveyHead?.[0]?.surveys_uid,
            type_id: surveyHead?.[0]?.type_id,
            feedbacks: filteredMappedData,
        };

        try {
            axios.post(apiUrl, requestData, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                    // 'Content-Type': 'application/json', // Adjust the content type if needed
                },
            })
                .then(async response => {
                    await successSwal('ສົ່ງຄຳຕອບສຳເລັດ')
                    fetchSurveyUserData()
                    setIsLoading(false)
                    navigate('/question')
                })
                .catch(error => {
                    // Handle errors
                    console.error('Error:', error);
                    setIsLoading(false)
                });

        } catch (error) {
            // console.log("Error: ", error)
        }
    }

    const onAnswer = (id) => {
        navigate("/question-out/" + id);
    }

    // console.log("===========>questionData",questionData)

    return (
        <>
            {surveyHead?.[0]?.surveys_status === "out" ? (
                <QuestionOut />
            ) : (
                <>
                    <div className='lg:hidden md:hidden'>
                        <Appbar title={'ຕອບຄຳຖາມ'} link={'/question'} icons={<LeftOutlined />}>

                            <div className=" max-sm:mt-3 ml-2 mr-2  flex flex-col  justify-center items-center" style={{ boxShadow: '1px 2px 9px #F4AAB9', }}>
                                <div className=' relative  max-sm:w-full  w-[100%] bg-[#FFFFFF] h-[100%] rounded-lg'>
                                    <div className=' absolute top-[-10px] w-full'>
                                        <Progress
                                            strokeLinecap="butt"
                                            style={{ width: "100%", borderRadius: "100px" }}
                                            showInfo={false}
                                            percent={progressPercentage} />
                                    </div>
                                    <div className=' mt-3 p-[20px]'>
                                        <div className=' text-center text-[16px] font-bold'>
                                            {currentQuestion + 1} ຈາກ {questionData.length}
                                        </div>
                                        <div className=' text-[16px] text-center mt-3'>{surveyHead?.[0]?.title ?? "-"}</div>
                                        <Divider />
                                        <div className=' text-[14px] mt-3'>ຄຳຖາມ :</div>
                                        <div className=' text-[14px] mt-2'> {currentQuestion + 1}. {questionData[currentQuestion]?.question_text}</div>
                                        <div className={` ${!questionData[currentQuestion]?.images ? " hidden" : "mt-3"}  `}><img width={'100%'} style={{ height: 250, objectFit: "cover" }} src={questionData[currentQuestion]?.images} /></div>
                                        {
                                            <Radio.Group
                                                className=' mt-[25px]'
                                                onChange={(e) => setSelectedOption((prevOptions) => {
                                                    const updatedOptions = [...prevOptions];
                                                    // updatedOptions[currentQuestion] = e.target.value;
                                                    updatedOptions[currentQuestion] = {
                                                        question_id: questionData[currentQuestion]?.question_id,
                                                        category_id: questionData[currentQuestion]?.category_id,
                                                        choice_id: e.target.value,
                                                    };
                                                    // console.log("==========>updatedOptions", updatedOptions)
                                                    return updatedOptions;
                                                })}
                                                value={selectedOption[currentQuestion]?.choice_id}
                                            >
                                                {getChoicesForQuestion(questionData[currentQuestion]?.question_id)?.map(choice => (
                                                    <div key={choice.choice_id}>
                                                        <Radio className='p-2' value={choice.choice_id}>  {choice?.choice_text}</Radio>
                                                    </div>
                                                ))}
                                            </Radio.Group>
                                        }
                                    </div>
                                    <div className=' w-full pl-[20px] pr-[20px] pb-[20px]'>
                                        <div className=' flex justify-end items-center'>
                                            {currentQuestion > 0 && (
                                                <Button className=' w-[50%] h-[40px]' style={{ marginRight: 8 }} onClick={() => handleNextQuestion(true)}>
                                                    ກັບຄືນ
                                                </Button>
                                            )}

                                            <Button
                                                type="primary" className={` ${currentQuestion + 1 < questionData.length ? "" : "hidden"}  bg-blue-600 w-[50%] h-[40px] hover:bg-black`} onClick={() => handleNextQuestion(false)}>
                                                ຕໍ່ໄປ
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    const optionData = selectedOption?.filter(item => item !== undefined && item !== null);
                                                    if (!optionData || optionData?.length !== questionData?.length) {
                                                        warring('ກະລຸນາເລືອກຄຳຕອບໃຫ້ຄົບຖວ້ນທຸກຂໍ້')
                                                    } else {
                                                        onFeedback();
                                                        // warring('successfully')
                                                    }
                                                }}
                                                type=""
                                                className={` ${currentQuestion + 1 < questionData.length ? "hidden" : ""} bg-green-600  w-[50%] h-[40px] text-white`}>
                                                {isLoading ? <Spinner size='sm' /> : "ສຳເລັດ"}
                                            </Button>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Appbar>
                    </div>
                    <div className={`max-sm:mt-3 max-sm:p-3 max-sm:hidden flex flex-col justify-center items-center`}>
                        <div className=' flex justify-start w-[100%]'>
                            <Alert
                                message={<Breadcrumb
                                    items={[
                                        {
                                            title: (
                                                <>
                                                    <LeftOutlined />
                                                    <span onClick={() => navigate('/question')} className=' ms-2 cursor-pointer'>ກັບຄືນລາຍການຟອມ</span>
                                                </>
                                            ),
                                        },
                                        {
                                            title: 'ຕອບຄຳຖາມ',
                                        },
                                    ]}
                                />}
                                type="info"
                                closable={false}
                                className=' w-full shadow-sm hover:shadow-lg  shadow-slate-900 '
                            />
                        </div>
                        {/* <Skeleton
                            loading={loadingChoice || loading}
                            paragraph={{
                                rows: 13,
                            }}
                            active div> */}
                        <div className=' relative mt-3  max-sm:w-full pb-5  w-[100%] bg-[#FFFFFF] h-[auto] rounded-lg' style={{ boxShadow: '1px 2px 9px #F4AAB9' }}>
                            <div className=' absolute top-[-10px] w-full'>
                                <Progress
                                    style={{ width: "100%" }}
                                    showInfo={false}
                                    percent={progressPercentage} />
                            </div>
                            <div className=' mt-3 p-[20px]'>
                                <div className=' text-center text-[16px] text-gray-900 font-bold' style={{ fontWeight: "bold" }}>
                                    {currentQuestion + 1} ຈາກ {questionData.length}
                                </div>
                                {loading ? <div className=' text-center mt-3'><Spin /></div> : <div className=' text-[20px] text-center mt-3 font-bold'>{surveyHead?.[0]?.title ?? "-"}</div>}
                                {/* <div>
                                    {surveyHead?.[0]?.title ?? "-"}
                                </div> */}
                                <Divider />
                                <div className=' text-[18px] mt-5 font-medium '>ຄຳຖາມ :</div>
                                <div className=' text-[18px] mt-2 font-medium'>
                                    {currentQuestion + 1}. {questionData[currentQuestion]?.question_text}
                                    {/* {questionData[currentQuestion]?.question_id} */}
                                    <div className={` ${!questionData[currentQuestion]?.images ? " hidden" : "mt-3"}  `}><img width={'100%'} style={{ height: 250, objectFit: "cover" }} src={questionData[currentQuestion]?.images} /></div>
                                </div>
                                {
                                    <Radio.Group
                                        className=' mt-[25px]'
                                        onChange={(e) => setSelectedOption((prevOptions) => {
                                            const updatedOptions = [...prevOptions];
                                            // updatedOptions[currentQuestion] = e.target.value;
                                            updatedOptions[currentQuestion] = {
                                                question_id: questionData[currentQuestion]?.question_id,
                                                category_id: questionData[currentQuestion]?.category_id,
                                                choice_id: e.target.value,
                                            };
                                            return updatedOptions;
                                        })}
                                        value={selectedOption[currentQuestion]?.choice_id}
                                    >
                                        {getChoicesForQuestion(questionData[currentQuestion]?.question_id)?.map(choice => (
                                            <div key={choice.choice_id}>
                                                <Radio className='p-2' value={choice.choice_id}>  {choice?.choice_text}</Radio>
                                            </div>
                                        ))}
                                    </Radio.Group>
                                }
                            </div>
                            <div className='  bottom-0 w-full  '>
                                <div className=' flex justify-end items-center mt-5'>

                                    {currentQuestion > 0 && (
                                        <Button className=' w-[20%] h-[40px]' style={{ marginRight: 10 }} onClick={() => handleNextQuestion(true)}>
                                            ກັບຄືນ
                                        </Button>
                                    )}

                                    <Button type="" className={` ${currentQuestion + 1 < questionData.length ? "" : "hidden"}  bg-blue-600 w-[20%] h-[40px] text-white mr-5`} onClick={() => handleNextQuestion(false)}>
                                        ຕໍ່ໄປ
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            const optionData = selectedOption?.filter(item => item !== undefined && item !== null);
                                            if (!optionData || optionData?.length !== questionData?.length) {
                                                warring('ກະລຸນາເລືອກຄຳຕອບໃຫ້ຄົບຖວ້ນທຸກຂໍ້')
                                            } else {
                                                onFeedback();
                                                // warring('successfully')
                                            }
                                        }}
                                        type=""
                                        className={` ${currentQuestion + 1 < questionData.length ? "hidden" : ""} bg-green-600  w-[20%] h-[40px] text-white mr-5`}>
                                        {isLoading ? <Spinner size='sm' /> : "ສຳເລັດ"}
                                    </Button>

                                </div>
                            </div>
                        </div>
                        {/* </Skeleton> */}
                    </div>
                </>
            )}

        </>
    )
}

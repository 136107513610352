import React, { useState, useEffect } from 'react'
import { PlusCircleOutlined, FileTextOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Result, Card, Button, Breadcrumb, Divider, Pagination, Radio, Popover } from 'antd';
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import AddQuestion from './AddQuestion';
import PopupConfirm from '../../components/PopupConfirm';
import { successSwal } from '../../constants/sweetalert';
const { Meta } = Card;

export default function CreateSurveyList() {

    const navigate = useNavigate()
    const [formType, setFormType] = useState('')
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
    const [deleted, setDeleted] = useState(false)
    const [addQuestion, setAddQuestion] = useState(false)
    const [questionsArray, setQuestionsArray] = useState([])
    const [categoryUsing, setCategoryUsing] = useState(true)
    const [open, setOpen] = useState(false);
    const [openCategory, setOpenCategory] = useState(false)
    const [title, setTitle] = useState("")
    const [selectedTitle, setSelectedTitle] = useState("")
    const [category, setCategory] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("")
    const itemsPerPage = 12;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const visibleQuestions = questionsArray.slice(startIndex, endIndex);

    useEffect(() => {
        if (categoryUsing === false) {
            setCategory('')
        }
    }, [categoryUsing])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const handleOpenChangeCategory = (newOpen) => {
        setOpenCategory(newOpen)
    }

    const handleDeleteQuestion = () => {
        if (selectedQuestionIndex !== null) {
            const newArray = [...questionsArray];
            newArray.splice(selectedQuestionIndex, 1);
            setQuestionsArray(newArray);
            successSwal("ລຶບສຳເລັດ")
        }
        setDeleted(false);
        setSelectedQuestionIndex(null);
    };

    const onSaveTitle = () => {
        try {
            setTitle(selectedTitle);
            successSwal("ເພິ່ມຫົວຂໍ້ສຳເລັດ");
            setSelectedTitle("");
            setOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    const onSaveCategory = () => {
        try {
            setCategory(selectedCategory);
            successSwal("ເພິ່ມຫົວຂໍ້ສຳເລັດ");
            setSelectedCategory("");
            setOpenCategory(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCategoryUsingChange = (e) => {
        setCategoryUsing(e.target.value);
    };

    // console.log("======>questionsArray", questionsArray)

    return (

        <div className=' sm:p-5 max-sm:p-5 md:p-0 lg:p-0'>
            <Breadcrumb
                className='text-[16px]'
                items={[
                    {
                        title: <span onClick={() => navigate("/survey-list")} className='cursor-pointer'>ໜ້າຫຼັກ</span>,
                    },
                    {
                        title: 'ສ້າງເເບບຟອມ'
                    },
                ]}
            />
            <div className='mt-[20px] grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2 pt-3 pb-3 '>
                <Card
                    onClick={() => setFormType('meeting')}
                    className={`text-center cursor-pointer ${formType === "meeting" ? ' bg-blue-500 text-[#FFFFFF]' : ''}`}
                    cover={<FileTextOutlined className='text-[30px] pt-[20px]' />}
                >
                    <Meta title={<span className={`${formType === "meeting" ? 'text-[#FFFFFF]' : ""}`} >ເເບບຟອມຄວາມເພິ່ງພໍໃຈ</span>} />
                </Card>

                <Card
                    onClick={() => setFormType('meetingRoom')}
                    className={`text-center cursor-pointer ${formType === "meetingRoom" ? 'bg-blue-500 text-[#FFFFFF]' : ''}`}
                    cover={<FileTextOutlined className='text-[30px] pt-[20px]' />} >
                    <Meta
                        title={<span className={`${formType === "meetingRoom" ? 'text-[#FFFFFF]' : ""}`}>ເເບບຟອມທົດສອບ</span>}
                    />
                </Card>

                <Card
                    onClick={() => setFormType('final')}
                    className={`text-center cursor-pointer ${formType === "final" ? 'bg-blue-500 text-[#FFFFFF]' : ''}`}
                    cover={<FileTextOutlined className='text-[30px] pt-[20px]' />}
                >
                    <Meta
                        title={<span className={`${formType === "final" ? 'text-[#FFFFFF]' : ""}`}>ເເບບຟອມສອບເສັງ</span>}
                    />
                </Card>
            </div>

            <div className={`${formType === "" ? "hidden" : ""}`}>
                <div className='mt-[20px]'>
                    <Popover
                        placement="right"
                        content={[
                            <div className='w-[350px] h-[150px] flex justify-center items-center flex-col'>
                                <Form.Control
                                    name='selectedTitle'
                                    value={selectedTitle}
                                    onChange={(e) => setSelectedTitle(e.target.value)}
                                    as="textarea"
                                    rows={8} />

                                <div className='mt-4'>
                                    <Button onClick={hide}>ຍົກເລິກ</Button>
                                    <Button onClick={() => onSaveTitle()} className='ms-3'>ບັນທືກ</Button>
                                </div>
                            </div>
                        ]}
                        title="ປ້ອນຊື່ຫົວຂໍ້"
                        trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}>
                        <Button
                            className='bg-blue-600'
                            disabled={title !== "" ? true : false}
                            type="primary"
                            icon={<PlusCircleOutlined />}>
                            ເພີ່ມຫົວຂໍ້
                        </Button>
                    </Popover>
                </div>

                <div className='mt-[20px] flex text-[16px] flex-col'>

                    <Divider className='mt-[0px]' />

                    <div className='Row mt-2'>
                        <Radio.Group onChange={handleCategoryUsingChange} value={categoryUsing}>
                            <Radio value={true}>ມີໝວດໝູ່</Radio>
                            <Radio value={false}>ບໍ່ມີໝວດໝູ່</Radio>
                        </Radio.Group>
                    </div>
                    <Divider />
                    <div className='text-[14px] flex items-center justify-between'>
                        <div>
                            <Popover
                                placement="right"
                                content={[
                                    <div className='w-[350px] h-[150px] flex items-center justify-center flex-col'>
                                        <Form.Control
                                            name='selectedCategory'
                                            value={selectedCategory}
                                            onChange={(e) => setSelectedCategory(e.target.value)}
                                            as="textarea"
                                            rows={8} />

                                        <div className='mt-4'>
                                            <Button onClick={hide}>
                                                ຍົກເລິກ
                                            </Button>
                                            <Button onClick={() => onSaveCategory()} className='ms-3'>
                                                ບັນທືກ
                                            </Button>
                                        </div>
                                    </div>
                                ]}
                                title="ປ້ອນຊື່ໝວດໝູ່"
                                trigger="click"
                                open={openCategory}
                                onOpenChange={handleOpenChangeCategory}>
                                <Button disabled={categoryUsing === false ? true : false}>ເພີ່ມຊື່ໜວດໝູ່</Button>
                            </Popover>
                            <Button className='ms-3' onClick={() => setAddQuestion(true)} >ເພີ່ມຄຳຖາມ</Button>
                        </div>
                        <Pagination
                            current={currentPage}
                            total={questionsArray.length}
                            pageSize={itemsPerPage}
                            onChange={handlePageChange}
                            style={{ marginTop: 20, textAlign: 'center' }}
                        />
                    </div>

                    {/* <Divider style={{ marginBottom: 0 }} /> */}
                    {title !== "" && (
                        <Card
                            className='mt-4 text-[18px] w-[50%] shadow-[1px 1px 2px #F4AAB9] flex flex-col'
                        >
                            <div className='flex'>
                                <div className='w-[15%]'>
                                    <div className='font-semibold'>ຫົວຂໍ້</div>
                                    <div className={`mt-2 font-semibold ${categoryUsing === false ? "hidden" : ""}`}>
                                        ໜວດໝູ່
                                    </div>
                                </div>
                                <div className='w-[80%]'>
                                    <div className=' flex justify-between'>:&ensp;{title}</div>
                                    <div className={`mt-2 ${categoryUsing === false ? "hidden" : ""}`}>:&ensp;{category}</div>
                                </div>
                            </div>
                        </Card>
                    )}
                </div>

                <div>
                    <div className=' grid grid-cols-1 gap-2 mt-[20px]'>
                        {visibleQuestions.map((e, index) => (
                            <div key={index}>
                                <Card
                                    title={<div className=' font-bold text-[18px]'>{index + 1 + startIndex + '.'} {e?.question}</div>}
                                    className=' w-full shadow-[1px 1px 2px #F4AAB9]'
                                    extra={[
                                        <div className='flex'>
                                            <div className=' cursor-pointer text-blue-600'>
                                                <EditOutlined key="edit" />
                                                <span className='ms-2'>ເເກ້ໄຂ</span>
                                            </div>
                                            <div className='ms-4 text-red-600 cursor-pointer' onClick={() => {
                                                setSelectedQuestionIndex(index);
                                                setDeleted(true)
                                                // handleDeleteQuestion(index)
                                            }}>
                                                <DeleteOutlined key="delete" />
                                                <span className='ms-2'>ລຶບ</span>
                                            </div>
                                        </div>
                                    ]}
                                >
                                    <Meta
                                        description={
                                            <div className='ms-3 text-[16px] text-black font-medium'>
                                                {
                                                    e?.answer?.map((ans, ansIndex) => (
                                                        <div className=' font-medium text-gray-600 flex items-center justify-between' key={ansIndex}>
                                                            <div className='text-[16px]'>
                                                                {ans?.answer}
                                                            </div>
                                                            <div className='text-[16px]'>
                                                                {/* <StarOutlined  style={{ fontSize: 18 }} /> */}
                                                                {ans?.score} <span className='ms-2'>ຄະເເນນ</span>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    />
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

            <div className={`${formType === "" ? 'flex' : 'hidden'} justify-center items-center h-[50vh]`}>
                <Result
                    icon={<InfoCircleOutlined style={{ color: "#33ccff" }} />}
                    title="ກາລຸນາເລືອກຮູບເເບບຟອມ"
                // extra={
                //     <Button type="primary" key="console">
                //         Go Console
                //     </Button>
                // }
                />
            </div>


            <AddQuestion
                show={addQuestion}
                handleClose={() => setAddQuestion(false)}
                setQuestionsArray={setQuestionsArray}
            />
            <PopupConfirm
                show={deleted}
                handleClose={() => setDeleted(false)}
                // icon={<CloseCircleOutlined style={{ fontSize: 80, color: "red" }} />}
                title={' ທ່ານ ຕອ້ງການລຶບຂໍ້ມູນນີ້ເເທ້ ຫຼື ບໍ່ '}
                // description={`ຫົວຂໍ້ : ${notiDetail?.msg_title} ນີ້ເເທ້ ຫຼື ບໍ່ !`}
                onSave={handleDeleteQuestion}
            // loading={loading}
            />

        </div >
    )
}



